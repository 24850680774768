'use strict';

/* eslint-env browser */

var handlebars = (typeof window !== "undefined" ? window['Handlebars'] : typeof global !== "undefined" ? global['Handlebars'] : null);

module.exports = function(options) {
  var jsonLocation = localStorage.getItem('agent.location') || sessionStorage.getItem('agent.location') || 'false';
  var agentLocation = JSON.parse(jsonLocation);

  if (agentLocation && agentLocation.branchCode === this.branchCode) {
    return new handlebars.SafeString(
      '<span class="agent-place">' +
      options.inverse(this) +
      '</span>'
    );
  } else {
    return new handlebars.SafeString(
      '<a class="agent-place" agent-place="true">' +
      options.fn(this) +
      '</a>'
    );
  }
};
