'use strict';

/**
 * Calculates a pagination offset based on an "nth" offset
 * @param  {String} statement - An "nth" based offset string (n-1, n, n+1, etc).
 * @param  {Object} paginate - The pagination options
 * @return {Int} - An integer representation of the statement
 */
function calculatePaginate(statement, paginate) {
  if (typeof statement === 'string' && statement.length) {
    if (statement[0] === 'n') {
      return parseInt(statement.substr(1)) + parseInt(paginate.startPage);
    } else {
      var n = parseInt(statement);

      if (n < 0) {
        return parseInt(paginate.endPage) + 1 + n;
      } else {
        return n;
      }
    }
  } else {
    return 0;
  }
}

module.exports = function(start, end, options) {
  if (typeof end !== 'string') {
    options = end;
    end = start;
  }

  var config = options.data.root;

  if (config) {
    var max = parseInt(config.endPage);

    start = Math.max(1, Math.min(max, calculatePaginate(start, config)));
    end = Math.max(1, Math.min(max, calculatePaginate(end, config)));

    var range = Array.apply(null, Array(end - start + 1)).map(function(_, i) {
      return i + start;
    });
    
    return range.map(function(n) {
      var within = options.hash.disableWithin || 0;
      
      return options.fn({
        'page': n,
        'disabled': Math.abs(n - config.startPage) <= within,
        'total': max,
        'active': n === config.startPage
      });
    }).join('');
  }
};
