var base = {
  '@context': 'http://schema.org/',
  '@type': 'Country',
  'alternateName': 'United States',
  'name': 'United States',
  'address': {
    '@type': 'PostalAddress',
    'addressCountry': 'United States'
  },
  'geo': {
    '@type': 'GeoCoordinates',
    'latitude': 39.381266,
    'longitude': -97.922211,
    'elevation': 6,
    'box': undefined
  }
};

module.exports = base;
