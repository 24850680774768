'use strict';

/* global Handlebars */

var extend = require('../util/extend');
var EventEmitter = require('../util/eventemitter');
var StorageMixin = require('../util/storage-mixin');
var AdvancedConfig = require('../schema/advanced-config');

var LocationMap = require('./location-map');
var LocationList = require('./location-list');

var defaultOptions = {
  container: undefined,
  listContainer: 'list-panel',
  mapContainer: 'map-panel'
};

/**
 * The `AdvancedLocator` components provides a full Locator experience
 * consisting of a LocationSearch start view, LocationMap and LocationList components
 * for viewing locations, and a LocationDetail view for viewing individual locations.
 *
 * The `AdvancedLocator` object mixes in [`EventEmitter`](#eventemitter) methods.
 *
 * @param {Object} options Locator options
 * @param {HTMLElement|string} options.container The HTML element in which
 * this component will be rendered into, or the element's string `id`.  This
 * argument is provided automatically if invoked as a jQuery plugin
 * @param {AdvancedConfig} options.config AdvancedLocator config object
 * @returns {AdvancedLocator} UI component

 */
var AdvancedLocator = function AdvancedLocator(options) {
  options = extend({}, defaultOptions, options);

  if (!options.container) {
    this._error('Must pass container ID or DOM element');
    return;
  }
  if (typeof options.container === 'string') {
    this._container = window.document.getElementById(options.container);
  } else {
    this._container = options.container;
  }

  $(this._container).on('click', '[action-select-location]', this._onPlaceSelected.bind(this));
  $(this._container).on('click', '[action-detail-location]', this._onDetailLocation.bind(this));
  $(this._container).on('click', '[action-change-location]', this._onChangeLocation.bind(this));

  this._container = options.container;
  this._listContainer = options.listContainer;
  this._mapContainer = options.mapContainer;
  this._state = {
    isLoading: false,
    isError: false,
    isLoaded: false,
    isDetail: false,
    selPlace: undefined
  };

  this._config = new AdvancedConfig(options.config);
  this._session = this._sessionStorage();
  this._session.setItem('place.viewed', '');
  this._session.setItem('place.selected', '');

  this.update(options);

  return this;
};

extend(AdvancedLocator.prototype, EventEmitter);
extend(AdvancedLocator.prototype, StorageMixin);
extend(AdvancedLocator.prototype, /** @lends AdvancedLocator.prototype */ {

  /**
   * Update the component with new options
   * @param {Object} options Map options
   * @param {Map} options.config locator configuration object in JSON-LD format.
   * @returns {undefined}
   */
  update: function(options) {
    if (options) {
      this._config.merge(options.config);
    }
    return this._updateTemplate().then(function() {
      this._render();
    }.bind(this));
  },

  /**
   * Factory function to create `LocationGeocoder` and return DOM element
   * @returns {HTMLElement} geocoder DOM element, rendered and ready to be added to page
   */
  _createGeocoder: function(options) {
    var geocoderEl = this._map.createGeocoder(options);
    document.getElementById('start-geocoder').appendChild(geocoderEl);
  },


  /**
   * Returns the Mapbox GL JS {@link https://www.mapbox.com/mapbox-gl-js/api/#Map|Map} object
   * @returns {Object} The Mapbox GL JS map
   */
  getMap: function() {
    return this._map.getMap();
  },

  /*
   * Check for new template, fetch if needed
   * @returns {Promise} promise resolved once template loaded
   * @private
   */
  _updateTemplate: function() {
    var deferred = $.Deferred();
    var templateString = this._config.getTemplateString('advancedLocator');
    var compiledTemplate = this._config.getcompiledTemplate('advancedLocator');
    var templateUrl = this._config.getTemplateURL('advancedLocator');

    // Load from URL
    if (templateUrl && !this.templateDownloaded) {
      $.get(templateUrl, function(source) {
        this.templateDownloaded = true;
        this._config.setTemplateString('advancedLocator', source);
        this._templateString = source;
        this._template = Handlebars.compile(source);
        return deferred.resolve();
      }.bind(this), 'html');
      return deferred.promise();
    }

    if (compiledTemplate) {
      // Load precompiled
      this._template = compiledTemplate;
      deferred.resolve();
    } else if (this._templateString !== templateString) { // eslint-disable-line no-negated-condition
      // Load from string, update if changed
      this._templateString = templateString;
      this._template = Handlebars.compile(templateString);
    } else {
      this._error('Missing template config');
      return deferred.reject();
    }
    deferred.resolve();

    return deferred.promise();
  },

  /**
   * Render the component
   * @returns {AdvancedLocator} this
   * @private
   */
  _render: function() {
    // Render container
    this._container.innerHTML = this._template(this._state);

    if (!this._map) {
      this._init();
    }
    this._refreshListeners();

    return this;
  },

  _hideGeolocate: function() {
    $(this._container).find('#start-geolocate')
    .hide();
  },

  _showGeolocate: function() {
    $(this._container).find('#start-geolocate')
    .show();
  },

  _hideMapPanel: function() {
    $(this._container).find('#map-panel')
    .hide();
  },

  _showMapPanel: function() {
    $(this._container).find('#map-panel')
    .show();
    this._map.getMap().resize();
  },

  _hideListPanel: function() {
    $(this._container).find('#list-panel')
    .hide();
  },

  _showListPanel: function() {
    $(this._container).find('#list-panel')
    .show();
  },

  _hideGeolocateInput: function() {
    $(this._container).find('.geolocate-input')
    .hide();
  },

  _showGeolocateInput: function() {
    $(this._container).find('.geolocate-input')
    .show();
  },

  _geolocateIsVisible: function() {
    return $(this._container).find('#start-geolocate').is(":visible");
  },

  _renderGeolocate: function(options) {
    var defaults = { stayHidden: false };
    options = $.extend(defaults, options || {});

    // Render whole template
    var html = this._template(this._state);

    // Extract elements to update
    var newErrorEl = $('.geolocate-error', html);
    var newLoadingEl = $('.geolocate-loading', html);

    try {
      // Update geolocate element
      var errorEl = $(this._container).find('.geolocate-error');
      errorEl.replaceWith(newErrorEl);
      var loadingEl = $(this._container).find('.geolocate-loading');
      loadingEl.replaceWith(newLoadingEl);
    } catch (e) {
      // Element may already be removed and this will quiet that error
    }

    if (this._state.isLoaded) {
      this._hideGeolocate();
      this._showMapPanel();
      this._showListPanel();
    } else if (!this._geolocateIsVisible() && !options.stayHidden) {
      this._showGeolocate();
      this._hideMapPanel();
      this._hideListPanel();
    }

    if (this._state.isLoading) {
      this._hideGeolocateInput();
    } else {
      this._showGeolocateInput();
    }

    this._refreshListeners();
  },

  _renderDetail: function() {
    console.log('render detail');
    var detEl = $(this._container).find('#detail-panel');

    // Render whole template and extract geolocate element
    var html = this._template(this._state);
    var newDetEl = $('#detail-panel', html);
    detEl.replaceWith(newDetEl);
  },

  /*
   * Initialize sub-components and handle or relay their events accordingly
   * @returns {undefined}
   * @private
   */
  _init: function() {
    var config = this._config.getConfig();

    this._map = new LocationMap({
      container: this._mapContainer,
      config: config
    });

    // Hide initially allowing for short start screen
    this._hideMapPanel();

    this._list = new LocationList({
      container: this._listContainer,
      config: config
    });

    // Hide initially allowing for short start screen
    this._hideListPanel();

    this._map.on('map.places.updated', this._onPlaceUpdate.bind(this));
    this._map.on('map.initialized', this._onMapInitialized.bind(this));
    this._map.on('map.loaded', function(event) {
      this.trigger('advanced.loaded');
    }.bind(this));

    this._map.on('map.geolocate.start', this._onGeolocateStart.bind(this));
    this._map.on('map.geolocate.error', this._onGeolocateError.bind(this));
    this._map.on('map.geolocated', this._onGeolocated.bind(this));
    this._map.on('map.geocoded', this._onGeocoded.bind(this));

    this._map.on('map.moving', this._onMapMoving.bind(this));
    this._map.on('map.moved', this._onMapMoved.bind(this));
    this._map.on('map.place.hovered', function(event, place) {
      this.trigger('advanced.place.hovered', place);
    }.bind(this));
    this._map.on('map.popup.opened', this._onPopupOpened.bind(this));
    this._map.on('map.popup.closed', this._onPopupClosed.bind(this));

    this._list.on('list.place.viewed', this._onPlaceViewed.bind(this));
  },

  /**
   * Refresh listeners after render
   * @returns {undefined}
   */
  _refreshListeners: function() {
    // Geolocation start button
    $('a[action-geolocate]').off('click');
    $('a[action-geolocate]').on('click', this._onGeolocateClick.bind(this));
  },

  _onMapInitialized: function() {
    this._createGeocoder();
    this.trigger('advanced.initialized');
  },

  _onGeolocateClick: function() {
    this._map._initGeolocation();
  },

  _onGeolocateStart: function() {
    this._state = $.extend(this._state, {
      isLoading: true,
      isError: false,
      isLoaded: false
    });

    // Re-render start panel, but keep hidden if already
    this._renderGeolocate({ stayHidden: true });

    // Show list loading during geolocation
    this._placeList = $.extend(this._placeList, {
      numberOfItems: -1
    });
    this._list.loadingPlaces(this._placeList);
  },

  _onGeolocateError: function(event, error) {
    this.trigger('advanced.geolocate.error', error);
    this._state = $.extend(this._state, {
      isLoading: false,
      isError: true,
      isPermissionError: error.code === 1,
      isPositionError: error.code === 2,
      isTimeoutError: error.code === 3,
      isLoaded: false
    });
    this._renderGeolocate();
    this._placeList = $.extend(this._placeList, {
      numberOfItems: this._placeList.itemListElement.length
    });
    this._list.loadingPlaces(this._placeList);
  },

  _onGeolocated: function(event, position) {
    this.trigger('advanced.geolocated', event, position);
    this._state = $.extend(this._state, {
      isLoading: false,
      isError: false,
      isPermissionError: false,
      isPositionError: false,
      isTimeoutError: false,
      isLoaded: true
    });
    this._renderGeolocate();
    this._placeList = $.extend(this._placeList, {
      numberOfItems: this._placeList.itemListElement.length
    });
    this._list.loadingPlaces(this._placeList);
  },

  _onGeocoded: function(result) {
    this.trigger('advanced.geocoded', result);
    this._state = $.extend(this._state, {
      isLoading: false,
      isError: false,
      isPermissionError: false,
      isPositionError: false,
      isTimeoutError: false,
      isLoaded: true
    });
    this._renderGeolocate();
  },

  _onMapMoving: function(event) {
    this.trigger('advanced.map.moving');
    this.trigger('advanced.map.moved');
    // Fix the list height
    var listEl = $('#' + this._listContainer);
    this.listHeight = $(listEl).css('height');
    listEl.css('height', this.listHeight);
    // Set list loading
    this._list.loadingPlaces({
      itemListElement: this._places,
      numberOfItems: -1 // Indicates loading
    });
  },

  _onMapMoved: function() {
    this.trigger('advanced.map.moved');
    this._list.loadingPlaces({
      itemListElement: [],
      numberOfItems: -1 // Indicates loading
    }).then(function() {
      // Unfix the list height
      if (this.listHeight) {
        var listEl = $('#' + this._listContainer);
        listEl.removeAttr('height').css({ height: '' });
        this.listHeight = undefined;
      }
    }.bind(this));
  },

  _onPlaceUpdate: function(event, places) {
    this.trigger('advanced.places.updated', [places]);
    this._placeList = {
      itemListElement: places,
      numberOfItems: places.length
    };
    this._list.updatePlaces(this._placeList);
  },

  _onPlaceViewed: function(event, listEvent, place) {
    this.trigger('advanced.place.viewed', place);
    this.viewedID = place.branchCode;
    this._map.showPlace(place, listEvent);
    this._session.setItem('place.viewed', JSON.stringify(place));
  },

  _onPlaceSelected: function(event, listEvent, place) {
    var placeId;
    placeId = event.currentTarget.getAttribute('action-select-location');
    if (!placeId) {
      placeId = event.currentTarget.getAttribute('place-id');
    }

    if (placeId) {
      var place = this._placeList.itemListElement.find(function(thePlace) {
        return thePlace.branchCode == placeId;  // eslint-disable-line eqeqeq
      });

      if (place) {
        this.trigger('advanced.place.selected', [listEvent, place]);
        this.selectedID = place.branchCode;
        this._session.setItem('place.selected', JSON.stringify(place));
      }
    }
  },

  _onDetailLocation: function(event) {
    var placeId;
    placeId = event.currentTarget.getAttribute('action-view-location');
    if (!placeId) {
      placeId = event.currentTarget.getAttribute('place-id');
    }

    if (placeId) {
      var place = this._placeList.itemListElement.find(function(thePlace) {
        return thePlace.branchCode == placeId;  // eslint-disable-line eqeqeq
      });

      if (place) {
        this._showDetail(place);
      }
    }
  },

  _onChangeLocation: function() {
    this._hideDetail();
  },

  _showDetail: function(place) {
    this.trigger('advanced.detail.entering', place);
    this._state = $.extend(this._state, {
      isDetail: true,
      selPlace: place
    });
    this._renderDetail();
  },

  _hideDetail: function() {
    this.trigger('advanced.detail.leaving', this._state.selPlace);
    this._state = $.extend(this._state, {
      isDetail: false,
      selPlace: undefined
    });
    this._renderDetail();
  },

  _onPopupOpened: function(event, mapEvent, place) {
    // Ignore event if ID already set
    if (this.selectedID === place.branchCode) {
      return;
    }
    this.selectedID = place.branchCode;
    this._session.setItem('place.viewed', JSON.stringify(place));

    this.trigger('advanced.popup.opened', [mapEvent, place]);
  },

  _onPopupClosed: function(event, place) {
    // Only clear if another marker hasn't already been selected (and popup opened)
    if (this.selectedID === place.branchCode) {
      this.selectedID = undefined;
      this._session.removeItem('place.viewed');
      this._list.update();
    }
  },

   /**
   * Fired when an error occurs.  Primary error reporting mechanism
   *
   * @event advanced.error
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @param {{error: string}|String} error error object or string
   * @returns {undefined}
   */
  _error: function(error) {
    // ToDo - check for handler and if not one then throw as normal
    var errObj;
    if (typeof error === 'string') {
      errObj = { error: new Error(error) };
    } else if (error instanceof Error) {
      errObj = error;
    }
    errObj.type = 'advanced';
    this.trigger('error', errObj);
  }

  /**
   * Fired after locator loaded
   *
   * @event advanced.loaded
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @return {undefined}
   */

   /**
   * Fired after geocoder result received
   *
   * @event advanced.geocoded
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @property {Object} result Mapbox geocoder result
   */

   /**
   * Fired after locations updated
   *
   * @event advanced.places.updated
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @property {Array} loctions An array of schema.org/LocalBusiness for each
   * location visible in the map viewport
   */

   /**
   * Fired after map clicked
   *
   * @event advanced.place.selected
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @property {Object} mapEvent Mapbox map click event Object
   * @property {Place} [place] the selected place
   */

   /**
   * Fired after place hovered
   *
   * @event advanced.place.hovered
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @property {LocalBusiness} place the place hovered
   */

   /**
   * Fired after popup opened
   *
   * @event advanced.popup.opened
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @property {Object} mapEvent Mapbox map click event Object
   * @property {Place} place the place popup opened for
   */

   /**
   * Fired when location detail clicked
   *
   * @event advanced.detail.entering
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @property {Place} place the place being viewed
   */

   /**
   * Fired when location detail closed
   *
   * @event advanced.detail.leaving
   * @memberof AdvancedLocator
   * @instance
   * @type {Object}
   * @property {Place} place the place that was being viewed
   */
});

module.exports = AdvancedLocator;
