'use strict';
/* eslint-env browser */

/*
 * Checks if current location matches currently selected
 */
module.exports = function(key, match, options) {
  if (typeof key === 'object') {
    options = key;
    key = match = undefined;
  }
  
  var selected = sessionStorage.getItem(key || 'place.selected');

  if (selected) {
    selected = JSON.parse(selected);
  } else {
    return options.inverse(this);  
  }

  if (selected.branchCode == (match || this.branchCode)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
};
