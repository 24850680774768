/* global __dirname */
'use strict';

var extend = require('../util/extend');
var schemaUtil = require('./util');
var basePlace = require('./base-place');
var baseAdvanced = require('./base-advanced');
var baseMap = require('./base-map');
var baseList = require('./base-list');
var BaseConfig = require('./base-config');
var TemplatedConfig = require('./templated-config');

var defaultConfig = schemaUtil.mergeConfigs([basePlace, baseAdvanced, baseMap, baseList]);

/**
* A `AdvancedConfig` is used to configure a [`AdvancedLocator`](#advancedlocator)
* component.  Utilizes a [`WebPageElement`](#webpageelement) schema and
* provides helper functions for working with.
* @param {WebPageElement} config map configuration object in JSON-LD format.
* @returns {AdvancedConfig} copy of the current map config object
*/
var AdvancedConfig = function AdvancedConfig(config) {
  this._config = defaultConfig;
  if (config) {
    this.merge(config);
  }

  return this;
};

extend(AdvancedConfig.prototype, BaseConfig);
extend(AdvancedConfig.prototype, TemplatedConfig);
extend(AdvancedConfig.prototype, /** @lends AdvancedConfig.prototype */ {});

module.exports = AdvancedConfig;
