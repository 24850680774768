'use strict';
var extend = require('../util/extend');

 /**
 * schema.org Object type {@link https://schema.org/WebPageElement|WebPageELement}.
 * A sub-type of [`Thing`](#thing) representing a web page element.
 * @typedef {(Object)} WebPageElement
 */

 /**
 * schema.org Object type {@link https://schema.org/LocalBusiness|LocalBusiness}.
 * A sub-type of [`Place`](#place) representing a local business.
 * @typedef {(Object)} LocalBusiness
 */

/**
 * schema.org Object type {@link https://schema.org/Map|Map}.
 * Used to represent a map view.
 * @typedef {(Object)} Map
 */

/**
 * schema.org Object type {@link https://schema.org/ItemList|ItemList}.
 * Used to represent a list of [`Places`](#place) or [`LocalBusinesses`](#localbusiness).
 * @typedef {(Object)} ItemList
 */

/**
 * schema.org Object type {@link https://schema.org/Place|Place}.
 * @typedef {(Object)} Place
 */

/**
 * schema.org Object type {@link https://schema.org/Thing|Thing}. 
 * The most generic type of item with base properties all types have in common.
 * @typedef {(Object)} Thing
 */

/**
 * schema.org Object type {@link https://schema.org/Action|Action}.
 * An action to be performed or that has already been completed.
 * @typedef {(Object)} Action
 */

/**
 * Util methods for working with schema.org objects
 *
 * @class SchemaUtil
 */
var SchemaUtil = {

  /**
  * Converts a Mapbox geocoder response to a schema.org/Place
  * @param  {Object} geocode - A Mapbox geocoder response
  * @param  {Place} place - An optional schema.org/Place to populate
  * @returns {Place} A schema.org/Place
  */
  geocodeToPlace: function(geocode, place) {
    place = place || {
      '@context': 'http://schema.org',
      'type': 'Place',
      'name': geocode.place_name,
      'address': { },
      'geo': {
        'latitude': geocode.geometry.coordinates[1],
        'longitude': geocode.geometry.coordinates[0]
      }
    };

    switch (geocode.id.split('.').shift()) {
      case 'address':
        place.address.streetAddress = geocode.text;
        break;
      case 'region': 
        place.address.addressRegion = geocode.text;
        break;
      case 'country':
        place.address.addressCountry = geocode.text;
        break;
      case 'place':
        place.address.addressLocality = geocode.text;
        break;
      case 'postcode':
        place.address.postalCode = geocode.text;
        break;
    }

    if (geocode.context) {
      geocode.context.forEach(function(context) {
        SchemaUtil.geocodeToPlace(context, place);
      });
    }

    return place;
  },

   /**
   * Converts GeoJSON feature with properties in GetLocal Pages
   * format to a schema.org/LocalBusiness, unless it's already
   * in that format.  Additionally, converts feature properties starting with
   * 'c_' to the LocalBusiness object under `additionalProperty`.  The new
   * property name assigned is converted to camelcase.  For example
   * 'c_phone_mobile' becomes `phoneMobile`.
   * @param  {Object} feature - A GeoJSON feature in Pages format
   * @returns {Place} A schema.org/LocalBusiness
   */
  featureToLocalBusiness: function(feature) {
    var business;
    if (feature.properties.store_code) {
      var props = feature.properties;
      if (props.hasOwnProperty('@context') &&
          props.type === 'LocalBusiness') {
        return props;
      }
      business = {
        '@context': 'http://schema.org/',
        'type': 'LocalBusiness',
        'branchCode': props.store_code,
        'name': props.business_name,
        'address': {
          'streetAddress': props.address_line_1,
          'postalCode': props.postal_code,
          'addressRegion': props.ST,
          'addressLocality': props.City
        },
        'telephone': props.primary_phone,
        'url': props.url,
        'geo': {
          'latitude': feature.geometry.coordinates[1],
          'longitude': feature.geometry.coordinates[0]
        },
        'openingHours': props.c_hours,
        'image': props.profile_photo,
        'additionalProperty': {
          'unitAddress': {
            'propertyId': 'unitAddress',
            'value': props.address_line_2
          }
        }
      };

      // Add additional feature properties to LocalBusiness that match pattern
      var attribMatch = 'c_';
      var attribExclude = 'c_hours';
      for (var prop in props) {
        if (prop.slice(0, 2) === attribMatch && prop !== attribExclude) {
          var propId = prop.slice(2).replace(/_[A-z]/g, function(match) {
            return match.slice(1).toUpperCase();
          });
          business.additionalProperty[propId] = {
            'propertyId': propId,
            'value': props[prop]
          };
        }
      }
    } else {
      business = $.extend(true, {}, feature.properties);

      if (business.hasOwnProperty('@context') && business['@type'] === 'LocalBusiness') {
        // Decode JSON strings
        Object.keys(business).forEach(function(property) {
          if (typeof business[property] === 'string') {
            try {
              business[property] = JSON.parse(business[property]);
            } catch (error) {
              // Do nothing
            }
          }
        });

        if (typeof business.additionalProperty === 'undefined') {
          business.additionalProperty = { };
        }
      }

      if (!business.geo) {
        business.geo = {
          'latitude': feature.geometry.coordinates[1],
          'longitude': feature.geometry.coordinates[0]
        };
      }
    }

    return business;
  },

  /**
   * Recursively deep copy the provided array or object and return new
   * @param  {Object | Array} src - object or array to copy
   * @return {Object | Array} new Object or Array
   * @private
   */
  copy: function(src) {
    var base;
    if (src.constructor === Array) {
      base = [];
    } else {
      base = {};
    }
    return $.extend(true, base, src);
  },

  mergeConfigs: function(configs) {
    var result = {};

    configs.forEach(function(config) {
      // Merge configs together except actions (extend not good at merging arrays)
      var newConfig = $.extend({}, config);
      newConfig.potentialAction = [];
      result = $.extend(true, result, newConfig);

      // Merge in actions
      if (config.potentialAction) {
        config.potentialAction.forEach(function(action) {
          var actionFields = SchemaUtil.findAction(action.name, config);
          SchemaUtil.setAction(action.name, result, actionFields, true);
        });
      }
    });

    return result;
  },

  /**
   * Retrieves a schema.org Thing from a config objects hasPart
   * @param  {String} name - The name of the Thing
   * @param  {Object} config - config object containing potentialAction array of schema.org/Thing
   * @param  {Boolean} multiple - Whether to return one or multiple matches   
   * @return {Object} A schema.org/Thing
   * @private
   */
  findPart: function(name, config, multiple) {
    var obj;
    if (config && Array.isArray(config.hasPart)) {
      if (multiple) {
        obj = config.hasPart.filter(function(configObj) {
          return configObj['@type'] === name || configObj.name === name;
        });
      } else {
        obj = config.hasPart.find(function(configObj) {
          return configObj['@type'] === name || configObj.name === name;
        });
      }
    }

    return obj;
  },

  /**
   * Retrieves a PotentialAction from an array by name
   * @param  {String} name - The name of the action
   * @param  {Array} [config] - Optional array of potential actions to search
   * @param  {Boolean} multiple - Whether to return one or multiple matches
   * @return {Object} A schema.org/PotentialAction
   */
  findAction: function(name, config, multiple) {
    var action;
    if (config && Array.isArray(config.potentialAction)) {
      if (multiple) {
        action = config.potentialAction.filter(function(potentialAction) {
          return potentialAction.name === name;
        });
      } else {
        action = config.potentialAction.find(function(potentialAction) {
          return potentialAction.name === name;
        });
      }
    }

    return action;
  },

  /**
   * Set the value of a PotentialAction by name
   * @param {String} name - the name of the action
   * @param {Object} config - config object with action to update
   * @param 
   * @return {undefined}
   */
  setAction: function(name, config, value, merge) {
    var index = config.potentialAction.findIndex(function(potentialAction) {
      return potentialAction.name === name;
    });
    if (index === -1) {
      index = config.potentialAction.length; // Add new value to end of array
    }
    if (merge) {
      config.potentialAction[index] = $.extend(true, config.potentialAction[index], value);
    } else {
      config.potentialAction[index] = $.extend(true, {}, value);
    }
  },

  /**
   * Retrieves a PotentialAction from an array by name
   * @param  {String} name - The name of the action
   * @param  {Array} [config] - Optional array of potential actions to search
   * @param  {Boolean} multiple - Whether to return one or multiple matches
   * @return {Object} A schema.org/PotentialAction
   */
  findActionByName: function(name, config, multiple) {
    var action;
    if (config && Array.isArray(config.potentialAction)) {
      if (multiple) {
        action = config.potentialAction.filter(function(potentialAction) {
          return potentialAction.name === name;
        });
      } else {
        action = config.potentialAction.find(function(potentialAction) {
          return potentialAction.name === name;
        });
      }
    }

    return action;
  }

};

module.exports = SchemaUtil;
