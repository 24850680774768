/* global $, Handlebars, __DEBUG__, console */
'use strict';

var extend = require('../util/extend');
var geo = require('../util/geo');
var schemaUtil = require('../schema/util');
var exceptions = require('../util/geocoder/exceptions');
var EventEmitter = require('../util/eventemitter');

var mapboxgl = require('mapbox-gl/src');
var LocationGeocoder = require('./location-geocoder');
var MapConfig = require('../schema/map-config');
var MapboxClient = require('mapbox/lib/services/geocoding');
var turfCircle = require('@turf/circle');
var turfCenter = require('@turf/center');
var turfBboxPolygon = require('@turf/bbox-polygon');
var turfBbox = require('@turf/bbox');

Handlebars.registerHelper('roundNumber', function(num, places) {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return num.toFixed(places);
});

var defaultOptions = {
  container: undefined,
  mapContainer: 'location-map',
  searchContainer: 'location-search'
};

/**
 * The `LocationMap` object represents a map component capable of displaying
 * locations and performing geocode searches.
 *
 * The `LocationMap` object mixes in [`EventEmitter`](#eventemitter) methods.
 *
 * @param {Object} options Map options
 * @param {HTMLElement|string} container The HTML element in which
 * this component will be rendered into, or the element's string `id`.  This
 * argument is provided automatically if invoked as a jQuery plugin
 * @param {Map} options.config map configuration object in JSON-LD format.
 * @returns {this} The LocationMap object
 * @example
 * var lmap = $('#map').LocationMap(schema);
 * //or
 * var lmap = new LocationMap({
 *   container: 'map',
 *   config: mapConfig,
 * });
 */
var LocationMap = function LocationMap(options) {
  options = extend({}, defaultOptions, options);

  if (!options.container) {
    this._error('Must pass container ID or DOM element');
  }
  if (typeof options.container === 'string') {
    this._container = window.document.getElementById(options.container);
  } else {
    this._container = options.container;
  }

  this._isLoaded = false;
  this._popup = false;

  this._mapContainer = options.mapContainer;
  this._searchContainer = options.searchContainer;
  this._config = new MapConfig();

  this._onStyleLoad = this._onStyleLoad.bind(this);
  this._onMapLoad = this._onMapLoad.bind(this);
  this._onMapError = this._onMapError.bind(this);
  this._onMapClick = this._onMapClick.bind(this);
  this._onMouseMove = this._onMouseMove.bind(this);
  this._onMapMoveStart = this._onMapMoveStart.bind(this);
  this._onMapMoveEnd = this._onMapMoveEnd.bind(this);
  this._onMapData = this._onMapData.bind(this);

  this.update(options);

  return this;
};

extend(LocationMap.prototype, EventEmitter);
extend(LocationMap.prototype, /** @lends LocationMap.prototype */ {

  /**
   * Update the component with new options
   * @param {Object} options Map options
   * @param {Map} options.config map configuration object in JSON-LD format.
   * @returns {undefined}
   */
  update: function(options) {
    if (options) {
      this._config.merge(options.config);
    }

    this._updateTemplate('locationMap').then(function() {
      this._render();
      if (!this._map) {
        this._init();
      }
    }.bind(this));
    this._updateTemplate('mapPopup');
  },

  /**
   * Centers the map on a place and displays a popup
   * @param {Place} place - the place to render a popup for
   * @param {Object} event An optional origin event
   * @returns {undefined}
   */
  showPlace: function(place, event) {
    this._map.easeTo({
      center: [place.geo.longitude, place.geo.latitude]
    });
    var popupConfig = this._config.getPopupConfig();
    event = event || { };

    if (popupConfig) {
      if (this._popup) {
        this._popup.remove();
      }

      this._showPopup(place, popupConfig.offset);

      event.type = 'map.popup.opened';
      this.trigger(event, [event, place]);
    }
  },

  /**
   * Returns the Mapbox GL JS {@link https://www.mapbox.com/mapbox-gl-js/api/#Map|Map} object
   * @returns {Object} The Mapbox GL JS map
   */
  getMap: function() {
    return this._map;
  },

  /*
   * Check for new map template, fetch if needed
   * @param {String} action the action to update the template for
   * @returns {Promise} promise resolved once template updated
   * @private
   */
  _updateTemplate: function(action) {
    // Define action-specific keys for storing local copy of config values
    var downloadKey = '_' + action + 'Downloaded';
    var templateStringKey = '_' + action + 'TemplateString';
    var templateKey = '_' + action + 'Template';

    var deferred = $.Deferred();
    var templateString = this._config.getTemplateString(action);
    var compiledTemplate = this._config.getcompiledTemplate(action);
    var templateUrl = this._config.getTemplateURL(action);

    // Load from URL
    if (templateUrl && !this[downloadKey]) {
      $.get(templateUrl, function(source) {
        this[downloadKey] = true;
        this._config.setTemplateString(action, source);
        this[templateStringKey] = source;
        this[templateKey] = Handlebars.compile(source);
        return deferred.resolve();
      }.bind(this), 'html');
      return deferred.promise();
    }

    if (compiledTemplate) {
      // Load precompiled
      this[templateKey] = compiledTemplate;
      deferred.resolve();
    } else if (this[templateStringKey] !== templateString) { // eslint-disable-line no-negated-condition
      // Load from string, update if changed
      this[templateStringKey] = templateString;
      this[templateKey] = Handlebars.compile(templateString);
    } else {
      this._error('Missing template config for ' + action);
      return deferred.reject();
    }
    deferred.resolve();

    return deferred.promise();
  },

  /**
   * Render the component
   * @returns {LocationMap} this
   * @private
   */
  _render: function() {
    if (this._container) {
      this._container.innerHTML = this._locationMapTemplate();
    }
  },

  /**
   * Initialize the component
   * @returns {undefined}
   * @private
   */
  _init: function() {
    this._initPlace().done(function() {
      this._initMap();
      this._initGeocoder();
    }.bind(this));
  },

  /**
   * Initialize the map Place as needed, including geocoding of
   * address
   * @returns {Promise} JQuery Promise
   * @private
   */
  _initPlace: function() {
    var deferred = $.Deferred();

    this._mapboxClient = new MapboxClient(this._config.getAccessToken());
    var address = this._config.getAddress();

    // If address configured but no geo
    if (typeof address === 'object' && this._config.hasDefaultGeo()) {
      // Geocode address
      var searchInput = [
        address.streetAddress,
        address.addressLocality,
        address.addressRegion,
        address.addressCountry,
        address.postalCode
      ].filter(function(value) {
        return value;
      }).join(', ');

      var request = this._mapboxClient.geocodeForward(searchInput, this._config.getGeocoderConfig());

      request.then(function(response) {
        var first;
        var newGeo;

        var bboxResults = response.entity.features.filter(function(feature) {
          return feature.hasOwnProperty('bbox');
        });

        // Use first result with bbox, if it exists, otherwise build bbox from point and radius
        if (bboxResults.length > 0) {
          first = bboxResults[0];
          newGeo = {
            latitude: first.geometry.coordinates[1],
            longitude: first.geometry.coordinates[0],
            box: this._getBounds(first.bbox, this._config.getMapRadius())
          };
        } else {
          first = response.entity.features[0];
          newGeo = {
            latitude: first.geometry.coordinates[1],
            longitude: first.geometry.coordinates[0],
            elevation: 12
          };
        }

        // Fix bbox for common searches that cross data line and produce funny results (e.g. US)
        if (exceptions[first.id]) {
          newGeo.box = exceptions[first.id].bbox;
        }
        this._config.setGeo(newGeo);
        return deferred.resolve();
      }.bind(this));

      request.catch(function(err) {
        this._error('error', { error: err });
        return deferred.reject();
      }.bind(this));
    } else {
      return deferred.resolve();
    }
    return deferred.promise();
  },

  /**
   * Initialize the map component
   * @returns {undefined}
   * @private
   */
  _initMap: function() {
    mapboxgl.accessToken = this._config.getAccessToken();

    this._map = new mapboxgl.Map({
      'container': this._mapContainer,
      'style': this._config.getStyleURL(),
      'center': this._config.getCenter(),
      'zoom': this._config.getZoom()
    });

    var bbox = this._config.getBox();
    if (Array.isArray(bbox)) {
      var options = geo.convertBounds(this._map, bbox);
      options.duration = 0;
      this._map.flyTo(options);

      if (__DEBUG__) {
        console.log('flyTo bbox', options);
      }
    }

    if (__DEBUG__) {
      this._map.on('dataloading', function(data) {
        console.log('data: loading');
      });
      this._map.on('data', function(data) {
          console.log('data: load');
      });
    }

    this._map.on('render', this._onStyleLoad);
    this._map.on('data', this._onMapData);
    this._map.on('load', this._onMapLoad);
    // Add geolocate control to the map.
    var geolocControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 300000
      },
      showUserLocation: false
    });
    geolocControl.on('error', this._onGeolocationError.bind(this));

    this._map.addControl(geolocControl);
    this._map.addControl(new mapboxgl.NavigationControl());
    this._map.dragRotate.disable();
    this._map.touchZoomRotate.disableRotation();
    this._map.scrollZoom.disable();
    setTimeout(function() {
      this.trigger('map.initialized');
    }.bind(this), 0);
  },

  /**
   * Initialize the geocoder component
   * @returns {undefined}
   * @private
   */
  _initGeocoder: function() {
    var geocoderEl = this.createGeocoder();
    document.getElementById(this._searchContainer).appendChild(geocoderEl);
  },

  _hasGeolocation: function() {
    var geolocConfig = this._config.getGeolocationConfig();
    return navigator.geolocation && geolocConfig.enabled;
  },

  /**
   * Initialize geolocation
   * @returns {undefined}
   * @private
   */
  _initGeolocation: function() {
    var geolocConfig = this._config.getGeolocationConfig();
    if (navigator.geolocation) {
      var newGeo = {};
      if (geolocConfig.mockLocation) {
        if (geolocConfig.mockError) {
          this.trigger('map.geolocate.start');
          newGeo.code = geolocConfig.mockError;
          setTimeout(function() {
            this.trigger('map.geolocate.error', newGeo);
          }.bind(this), 2000);
        } else {
          this.trigger('map.geolocate.start');
          newGeo = {
            latitude: 45.548727299999996,
            longitude: -122.58010519999999,
            elevation: 10
          };
          this._config.setGeo(newGeo);
          setTimeout(function() {
            this.trigger('map.geolocated', newGeo);
            this._map.flyTo({
              center: [newGeo.longitude, newGeo.latitude],
              zoom: newGeo.elevation,
              duration: 0
            });
          }.bind(this), 2000);
        }
      } else {
        this.trigger('map.geolocate.start');
        navigator.geolocation.getCurrentPosition(
          this._onGeolocationSuccess.bind(this),
          this._onGeolocationError.bind(this),
          {
            enableHighAccuracy: false,
            timeout: 10000,
            maximumAge: 300000
          });
      }
    }
  },

  /**
   * Factory function to create `LocationGeocoder` and return DOM element
   * @returns {HTMLElement} geocoder DOM element, rendered and ready to be added to page
   */
  createGeocoder: function(options) {
    var geocoderConfig = $.extend({}, options, this._config.getGeocoderConfig());
    geocoderConfig.accessToken = this._config.getAccessToken();
    var geocoder = new LocationGeocoder(geocoderConfig);

    geocoder.on('result', function(geocode) {
      var result = schemaUtil.geocodeToPlace(geocode.result);
      var radius = this._config.getGeocoderRadius();

      // If no results, zoom out to geocoder radius if available
      if (radius && geocode.result.bbox && !this._geoBox) {
        this._geoBox = true;
        this.one('map.places.updated', function(event, places) {
          this._geoBox = false;

          if (!places.length) {
            this._map.fitBounds(this._getBounds(geocode.result.bbox, radius));
            if (__DEBUG__) {
              console.log('fitBounds');
            }
          }
        }.bind(this));
      }

      this.trigger('map.geocoded', result);
    }.bind(this));

    var geocoderEl = geocoder.onAdd(this._map);
    return geocoderEl;
  },

  _onGeolocationSuccess: function(position) {
    var geolocConfig = this._config.getGeolocationConfig();
    var newGeo = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      elevation: geolocConfig.elevation
    };
    this._config.setGeo(newGeo);
    this.trigger('map.geolocated', position);
    this._map.flyTo({
      center: [newGeo.longitude, newGeo.latitude],
      zoom: newGeo.elevation,
      duration: 0
    });
  },

  _onGeolocationError: function error(geoError) {
    this.trigger('map.geolocate.error', geoError);
    this._error(geoError);
  },

  _onMapLoad: function() {
    this._isLoaded = true;
    this.trigger('map.loaded');

    this._addMarkerLayers();

    if (__DEBUG__) {
      console.log('MAP.LOAD');
    }
    this._map.on('movestart', this._onMapMoveStart);
    this._map.on('moveend', this._onMapMoveEnd);
    this._map.on('error', this._onMapError);
    this._map.on('mousemove', this._onMouseMove);
    this._map.on('click', this._onMapClick);
  },

  _onMapMoveStart: function() {
    this.trigger('map.moving');
  },

  /**
   * Handle map move event.  Triggers `map.places.updated` event.
   * @return {undefined}
   * @private
   */
  _onMapMoveEnd: function() {
    this.trigger('map.moved');
    this._queryCheck();
  },

  /**
   * Handle map data event.
   * @return {undefined}
   * @private
   */
  _onMapData: function() {
    this._queryCheck();
  },

  /**
   * Queries the latest places sorted by distance and triggers a
   * `map.places.updated` event.
   * @return {undefined}
   * @private
   */
  _queryCheck: function() {
    if (__DEBUG__) {
      console.log('do check: map loaded:' + this._isLoaded + ', tilesloaded:' + this._map.areTilesLoaded());
    }

    if (this._map.areTilesLoaded() && !this._map.isMoving() && this._isLoaded) {
      if (__DEBUG__) {
        console.log('do query');
      }
      this._queryFeatures();
    }
  },

  _queryFeatures: function() {
    var places = [];

    var mapCenter = this._map.getCenter();
    var centerGeo = {
      'geo': {
        'latitude': mapCenter.lat,
        'longitude': mapCenter.lng
      }
    };

    var layerConfigs = this._config.getLayerConfigs();
    if (layerConfigs) {
      var ids = { };

      var locFeatures = this._map.queryRenderedFeatures({
        'layers': layerConfigs.map(function(layerConfig) {
          return layerConfig.propertyID;
        })
      });

      if (__DEBUG__) {
        console.log('location features:', locFeatures);
      }

      locFeatures.forEach(function(feature) {
        var place = schemaUtil.featureToLocalBusiness(feature);

        if (!place.additionalProperty) {
          place.additionalProperty = {};
        }

        if (!ids[place.branchCode]) {
          ids[place.branchCode] = true;

          place.additionalProperty.layerId = {
            'value': feature.layer.id
          };
          place.additionalProperty.distance = {
            'value': geo.haversineDistance(place, centerGeo)
          };

          places.push(place);
        }
      });
    }

    places.sort(function(a, b) {
      return a.additionalProperty.distance.value - b.additionalProperty.distance.value;
    });

    this._config.setGeo({
      latitude: this._map.getCenter().lat,
      longitude: this._map.getCenter().lng,
      box: this._map.getBounds(),
      elevation: this._map.getZoom()
    });

    this.trigger('map.places.updated', [places]);
  },

  /**
   * Trigger error event
   * @param {string} error the Error object
   * @return {undefined}
   * @private
   */
  _onMapError: function(error) {
    if (this._map.loaded()) {
      this._error(error);
    }
  },

  /**
   * on style load, makes any runtime style changes before map load event
   * @return {undefined}
   */
  _onStyleLoad: function() {
    if (this._map.isStyleLoaded()) {
      this._map.off('render', this._onStyleLoad);
      var layerConfigs = this._config.getLayerConfigs();
      layerConfigs.forEach(function(layerConfig) {
        if (layerConfig.url === undefined) {
          if (layerConfig.markerFilter) {
            this._addMarkerFilter(layerConfig);
          }
        }
      }.bind(this));
    }
  },

  /**
   * On mouse move on map change pointer icon
   * @param {Object} event the jQuery mouse event
   * @return {undefined}
   * @private
   */
  _onMouseMove: function(event) {
    var result = this._queryPointFeature(event.point);
    if (result.feature) {
      if (result.type === 'cluster') {
        this._map.getCanvas().style.cursor = 'zoom-in';
      } else if (result.feature) {
        this._map.getCanvas().style.cursor = 'pointer';
        this.trigger('map.place.hovered', schemaUtil.featureToLocalBusiness(result.feature));
      }
    } else {
      this._map.getCanvas().style.cursor = '';
    }
  },

  /**
   * Map click handler to show popups or zoom in on clusters
   * @param  {Object} mapEvent - The Mapbox GL click event object
   * @return {undefined}
   * @private
   */
  _onMapClick: function(mapEvent) {
    var popupConfig = this._config.getPopupConfig();
    var result = this._queryPointFeature(mapEvent.point);

    if (result && result.feature) {
      if (result.type === 'cluster') {
        this._map.zoomTo(this._map.getZoom() + 1, { 'around': event.lngLat }, event);
      } else {
        var place = schemaUtil.featureToLocalBusiness(result.feature);

        if (popupConfig) {
          // Trigger a popup event after a click event
          this.one('map.place.viewed', function(event, mapEvent2, place2) {
            var markerZoom = this._config.getMarkerZoom();
            var easeOptions = {
              center: [place.geo.longitude, place.geo.latitude]
            };
            if (markerZoom && this._map.getZoom() < markerZoom) {
              easeOptions.zoom = markerZoom;
            }
            this._map.easeTo(easeOptions);
            this._showPopup(place, popupConfig.offset);

            // Reuse click event for popup event
            event.type = 'map.popup.opened';
            this.trigger(event, [mapEvent2, place2]);
          }.bind(this));
          this.trigger('map.place.viewed', [mapEvent, place]);
        } else if (place.url) {
          window.location.href = place.url;
        }
      }
    }
  },

  /**
   * Renders and displays a popup using currently configured template
   * @param {Place} place - the place to render a popup for
   * @param {integer} offset - the icon offset
   * @return {undefined}
   * @private
   */
  _showPopup: function(place, offset) {
    this._popup = new mapboxgl.Popup({ offset: offset || 0 });
    this._popup.on('close', function() {
      this.trigger('map.popup.closed', place);
    }.bind(this));

    this._popup.setLngLat([
      place.geo.longitude,
      place.geo.latitude
    ])
    .setHTML(this._mapPopupTemplate(place))
    .addTo(this._map);
  },

  /**
   * Add location marker layers
   * @return {undefined}
   * @private
   */
  _addMarkerLayers: function() {
    var markerSource = {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': []
      }
    };

    /*
    var clusterConfig = this._config.getClusterConfig();
    // Clustering enabled if clusterMaxZoom greater than zero
    if (clusterConfig && clusterConfig.maxZoom !== 0) {
      markerSource.cluster = true;
      markerSource.clusterMaxZoom = clusterConfig.maxZoom;
      markerSource.clusterRadius = clusterConfig.geoRadius * 3;
    }
    */

    this._map.addSource('markers', markerSource);

    var layerConfigs = this._config.getLayerConfigs();
    if (layerConfigs) {
      layerConfigs.forEach(function(layerConfig) {
        if (layerConfig.url) {
          this._addMarkerLayer(layerConfig);
        }
      }.bind(this));
    }
    // addClusterLayers(map, cluster);
  },

  /**
   * Adds feature filter to an existing feature layer
   * @param {object} layerConfig mapTileset config
   * @return {undefined}
   */
  _addMarkerFilter: function(layerConfig) {
    // Get current filter
    var filters = this._map.getFilter(layerConfig.propertyID);
    var lFilter = layerConfig.markerFilter;
    // If no filter then create new combined type so we can append
    if (!filters) {
      filters = [['all']];
    }

    // Default to a boolean equality filter but if array then assume GL-JS filters
    if (typeof lFilter === 'string') {
      if (typeof filters[0] === 'string') {
        filters = ['all', filters];
      }
      filters.push(['==', lFilter, true]);
    } else if (Array.isArray(lFilter)) {
      filters = lFilter;
    }
    this._map.setFilter(layerConfig.propertyID, filters);
  },

  /**
   * Adds supporting layers for a feature layer to a map
   * @param {Object} layerConfig - one or more layer config objects
   * @return {undefined}
   * @private
   */
  _addMarkerLayer: function(layerConfig) {
    // var filter = this._config.findAction('filterMarkers', markerConfig);
    // var shadow = this._config.findAction('renderShadow', markerConfig);
    // var label = this._config.findAction('renderLabel', markerConfig);
    // filter = filter && filter.value ? filter.value : [];
    // filter.unshift('all');
    // filter.push(['!has', 'point_count']);
    // filter.push(['==', 'layerId', layerConfig.name]);

    // Shadow layer in background
    // if (shadow) {
    //   addShadowLayer(this._map, shadow, layerConfig.name + '-' + i + '.marker-shadow', filter);
    // }

    // Marker layer
    var markerLayer = {
      'id': layerConfig.propertyID,
      'source': {
        'type': 'vector',
        'url': layerConfig.url
      },
      'source-layer': layerConfig.sourceLayer
      // "layout": {
      //     "icon-image": "marker",
      //     "icon-size": 0.75,
      //     "icon-offset": [
      //         -5,
      //         -24
      //     ]
      // },
      // "type": "symbol"
    };

    this._configureLayer(markerLayer, layerConfig, 'icon');
    this._map.addLayer(markerLayer);

    // Label layer in foreground
    // if (label) {
    //   addLabelLayer(this._map, label, layerConfig.name + '-' + i + '.marker-label', filter);
    // }
  },

  /**
   * Converts a renderMarker PotentialAction to a MapboxGL layer configuration
   * object.
   * @param  {Object} layer - The MapboxGL layer configuration object to update
   * @param {Object} config - The mapTileset config
   * @param  {String} type - The type of layer
   * @return {undefined}
   * @private
   */
  _configureLayer: function(layer, config, type) {
    // Types, special case for icon and text
    layer.type = type === 'icon' || type === 'text' ? 'symbol' : type;

    if (!layer.layout) {
      layer.layout = { };
    }
    if (!layer.paint) {
      layer.paint = { };
    }

    // Translations
    if (config.offset && Array.isArray(config.offset)) {
      if (layer.type === 'symbol') {
        layer.layout[type + '-offset'] = [
          config.offset[0] || 0,
          config.offset[1] || 0
        ];
      } else {
        layer.paint[type + '-translate'] = [
          config.offset[0] || 0,
          config.offset[1] || 0
        ];
      }

      if (config.markerScale) {
        layer.layout[type + '-size'] = config.markerScale;
      }
    }

    // Opacity
    if (layer.opacity) {
      layer.paint[type + '-opacity'] = layer.opacity;
    }
    // Radius
    if (layer.radius) {
      layer.paint[type + '-radius'] = layer.radius;
    }
    // Color
    if (layer.color) {
      layer.paint[type + '-color'] = layer.color;
    }
    // Allow intersections
    if (layer.type === 'symbol') {
      layer.layout['icon-allow-overlap'] = true;
      layer.layout['icon-ignore-placement'] = true;
      layer.layout['text-allow-overlap'] = true;
      layer.layout['text-ignore-placement'] = true;
    }
    // Icon
    if (config.marker) {
      layer.layout[type + '-image'] = config.marker;
    }
    var branchCode = this._config.getBranchCode();
    if (branchCode) {
      layer.layout[type + '-image'] = {
        property: 'branchCode',
        type: 'categorical',
        stops: [
          [branchCode, config.marker]
        ],
        default: config.markerSecondary
      };
    }
    // Label
    if (config.description) {
      layer.layout[type + '-field'] = config.description;
    }
    // Filter, default to an equality filter but if array then assume GL-JS filters
    if (config.markerFilter) {
      if (typeof config.markerFilter === 'string') {
        layer.filter = ['==', config.markerFilter, true];
      } else if (Array.isArray(config.markerFilter)) {
        layer.filter = config.markerFilter;
      }
    }
    // Label color
    if (layer.contentSize) {
      layer.layout[type + '-size'] = config.contentSize;
    }
  },

  /**
   * Gets the bounds for a region respecting a minumum radius
   * @param  {[Number]} box - The x1, y1, x2, and y2 coordinates
   * @param  {Number} radius - The radius to cover in km
   * @return {[[Number]]} geographic Bounds
   */
  _getBounds: function(box, radius) {
    if (radius) {
      var poly = turfBboxPolygon(box);  // Turn bbox into polygon
      var center = turfCenter(poly);  // Get center point
      var circle = turfCircle(center, radius, 16, 'miles'); // Create circle
      var bbox = turfBbox(circle); // Get rectangle extent of circle
      var bounds = new mapboxgl.LngLatBounds(bbox);
      bounds.extend([[box[0], box[1]], [box[2], box[3]]]);
      return bounds.toArray();
    }

    return [[box[0], box[1]], [box[2], box[3]]];
  },

  /**
   * Queries the provided point on the map for features
   * @param  {Object} point - A MapboxGL Point
   * @return {Object} - queryResult with properties `feature` and `type`
   * @private
   */
  _queryPointFeature: function(point) {
    // var mapConfig = this._config.getMapConfig();
    // var feature = this._config.findAction('renderCluster', options);
    var feature;
    var type = false;

    /*
    if (feature) {
      feature = map.queryRenderedFeatures(point, {
        'layers': ['cluster']
      }).shift();
    }
    */

    if (feature) {
      type = 'cluster';
    } else {
      feature = this._map.queryRenderedFeatures(point, {
        'layers': this._getMouseLayers()
      }).shift();
    }

    return {
      feature: feature,
      type: type
    };
  },

  /**
   * Walks the "renderMap" configuration to calculate all of the layers relevant
   * to mouse events.
   * @param  {Object} schema - A schema.org/Map object
   * @return {[String]} - An array of layer names
   * @private
   */
  _getMouseLayers: function() {
    var layers = [];
    var layerConfigs = this._config.getLayerConfigs();

    layerConfigs.forEach(function(layerConfig) {
      layers.push(layerConfig.propertyID);

      /*
      var markerConfigs = this._config.findAction('renderMarker', source, true);
      if (markerConfigs) {
        markerConfigs.forEach(function(marker, i) {
          if (this._config.findAction('renderLabel', markerConfig)) {
            layers.push(source.name + '-' + i + '.marker-label');
          }
        });
      }
      */
    });

    return layers;
  },

  /**
   * Fired after map is instantiated and added to DOM
   *
   * @event map.initialized
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @return {undefined}
   */

  /**
   * Fired after map loaded
   *
   * @event map.loaded
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @return {undefined}
   */

   /**
   * Fired after geolocation position received
   *
   * @event map.geolocated
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @property {Object} position Geolocation position
   */

   /**
   * Fired after geolocation position received
   *
   * @event map.geolocate.error
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @property {Object} error Geolocation error
   */

   /**
   * Fired after geocoder result received
   *
   * @event map.geocoded
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @property {Object} result Mapbox geocoder result
   */

   /**
   * Fired after locations updated
   *
   * @event map.moved
   * @memberof LocationMap
   * @instance
   * @type {Object}
   */

   /**
   * Fired after locations updated
   *
   * @event map.places.updated
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @property {Array} loctions An array of schema.org/LocalBusiness for each
   * location visible in the map viewport
   */

   /**
   * Fired after map clicked
   *
   * @event map.place.viewed
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @property {Object} mapEvent Mapbox map click event Object
   * @property {Place} [place] the viewed place
   */

   /**
   * Fired after place hovered
   *
   * @event map.place.hovered
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @property {LocalBusiness} place the place hovered
   */

   /**
   * Fired after popup opened
   *
   * @event map.popup.opened
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @property {Object} mapEvent Mapbox map click event Object
   * @property {Place} place the place popup opened for
   */

   /**
   * Fired when an error occurs.  Primary error reporting mechanism
   *
   * @event map.error
   * @memberof LocationMap
   * @instance
   * @type {Object}
   * @param {Object|string} error - error message or Error instance
   * @property {{error: {message: string}}} eventData
   * @returns {undefined}
   */
  _error: function(error) {
    // ToDo - check for handler and if not one then throw as normal
    var errObj;
    if (typeof error === 'string') {
      errObj = { error: new Error(error) };
    } else {
      errObj = error;
    }
    errObj.type = 'map';
    this.trigger('error', [errObj]);
  }
});

module.exports = LocationMap;

