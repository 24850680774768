'use strict';

var $ = jQuery = global.jQuery;

/**
 * Utility function for wrapping an object into a jQuery plugin
 * 
 * @param {string} pluginName function name to attach to jQuery namespace
 * @param {Object} Plugin object method to create plugin
 * @returns {Object} instance of Plugin
 *
 * @example 
 * createPlugin('LocationMap', locator.LocationMap);
 * var $map = $('#map-panel');
 * $map.LocationMap({schema: schema});
 *
 * @private
 */
exports.createPlugin = function(pluginName, Plugin) {
  $.fn[pluginName] = function(options) {            
    /** 
     * Start plugin for given element
     * @this DOM element 
     * @private
     */
    function startPlugin() {
      // Keep plugin reference. Update options if already instantiated for this element
      var plugin = $.data(this, 'plugin_' + pluginName);
      // Add container to options
      if (!options) {
        options = {};
      }
      options.container = this;

      if (plugin) {
        plugin.update(options);
      } else {
        plugin = new Plugin(options);                
      }
      $.data(this, 'plugin_' + pluginName, plugin);
      
      return plugin;
    }
    
    return this.each(startPlugin);
  };
};
