'use strict';

/**
 * Methods mixed in to other components for storage capabilities.
 *
 * @mixin StorageMixin
 */
var StorageMixin = {

  /**
   * Checks whether a persistent storage type is available
   * @param  {String} type - localStorage or sessionStorage
   * @return {Boolean} true if the storage type is functional, false otherwise
   */
  _hasStorage: function(type) {
    try {
      var storage = window[type], x = '__storage_test__';
      
      storage.setItem(x, x);
      storage.removeItem(x);
      
      return true;
    } catch(error) {
      return false;
    }
  },

  /**
   * Sets up the sessionStorage to a persistent store, falling back to a memory store
   * @return {Storage} A Storage compatible object
   */
  _sessionStorage: function() {
    return this._hasStorage('sessionStorage') ? sessionStorage : this._memoryStorage();
  },

  /**
   * Sets up the localStorage to a persistent store, falling back to a memory store
   * @return {Storage} A Storage compatible object
   */
  _localStorage: function() {
    return this._hasStorage('localStorage') ? localStorage : this._memoryStorage();
  },

  /**
   * Creates an in memory Storage compatible object that will lose data on page navigation
   * @return {Storage} A Storage compatible object
   */
  _memoryStorage: function() {
    return {
      _data: { },
      setItem: function(id, value) {
        return this._data[id] = String(value);
      },
      getItem: function(id) {
        return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
      },
      removeItem: function(id) {
        return delete this._data[id];
      },
      clear: function() {
        return this._data = {};
      }
    };
  }
};

module.exports = StorageMixin;
