'use strict';

var extend = require('../util/extend');
var schemaUtil = require('./util');

/**
* The `BaseConfig` mixin provides essential methods for working with config objects
* @mixin BaseConfig
*/
var BaseConfig = {

  /**
   * Returns copy of config object.
   * @returns {SimpleConfig} a copy of the current `SimpleConfig` object
   */
  getConfig: function(action) {
    if (action) {
      return this.copy(this.findAction(action));
    }
    return this.copy(this._config);
  },

  /**
   * Override current configuration with new properties.  Returns new config object.
   * @param {Object} config object
   * @returns {Config} a copy of the new merged config
   */
  merge: function(config, action) {
    var newConfig = config || {};

    // Merge new config except actions (extend not good at merging arrays)
    var newBaseConfig = $.extend({}, newConfig);  // Deep copy
    newBaseConfig.potentialAction = [];
    this._config = $.extend(true, {}, this._config, newBaseConfig); // Merge

    // Merge in actions
    var actionFields;
    if (action) {
      actionFields = schemaUtil.findAction(action, newConfig);
      this.setAction(action, actionFields, true);
    } else if (newConfig.potentialAction) {
      newConfig.potentialAction.forEach(function(newAction) {
        actionFields = schemaUtil.findAction(newAction.name, newConfig);
        this.setAction(newAction.name, actionFields, true);
      }.bind(this));
    }

    return this.copy(this._config);
  },

  /**
   * Recursively deep copy the provided array or object and return new
   * @param  {Object | Array} src - object or array to copy
   * @return {Object | Array} new Object or Array
   * @private
   */
  copy: function(src) {
    var base;
    if (!src) {
      return undefined;
    }
    if (src.constructor === Array) {
      base = [];
    } else {
      base = {};
    }
    return $.extend(true, base, src);
  },

  /**
   * Retrieves a schema.org Thing from a config objects hasPart
   * @param  {String} name - The name of the Thing
   * @param  {Boolean} multiple - Whether to return one or multiple matches   
   * @return {Object} A schema.org/Thing
   * @private
   */
  findPart: function(name, multiple) {
    var obj;
    if (this._config && Array.isArray(this._config.hasPart)) {
      if (multiple) {
        obj = this._config.hasPart.filter(function(configObj) {
          return configObj['@type'] === name || configObj.name === name;
        });
      } else {
        obj = this._config.hasPart.find(function(configObj) {
          return configObj['@type'] === name || configObj.name === name;
        });
      }
    }

    return obj;
  },

  /**
   * Retrieves a PotentialAction from an array by name
   * @param  {String} name - The name of the action
   * @param  {Boolean} multiple - Whether to return one or multiple matches
   * @return {Object} A schema.org/PotentialAction
   */
  findAction: function(name, multiple) {
    var action;
    if (this._config && Array.isArray(this._config.potentialAction)) {
      if (multiple) {
        action = this._config.potentialAction.filter(function(potentialAction) {
          return potentialAction.name === name;
        });
      } else {
        action = this._config.potentialAction.find(function(potentialAction) {
          return potentialAction.name === name;
        });
      }
    }

    return action;
  },

  /**
   * Set the value of a PotentialAction by name
   * @param {String} name - the name of the action
   * @param {Object} value - of the action
   * @param {Boolean} merge - whether to merge into or overwrite action
   * @return {undefined}
   */
  setAction: function(name, value, merge) {
    var index = this._config.potentialAction.findIndex(function(potentialAction) {
      return potentialAction.name === name;
    });
    if (index === -1) {
      index = this._config.potentialAction.length; // Add new value to end of array
    }
    if (merge) {
      this._config.potentialAction[index] = $.extend(true, this._config.potentialAction[index], value);
    } else {
      this._config.potentialAction[index] = $.extend(true, {}, value);
    }
  },

  /**
   * Retrieves a PotentialAction from an array by name
   * @param  {String} name - The name of the action
   * @param  {Boolean} multiple - Whether to return one or multiple matches
   * @return {Object} A schema.org/PotentialAction
   */
  findActionByName: function(name, multiple) {
    var action;
    if (this._config && Array.isArray(this._config.potentialAction)) {
      if (multiple) {
        action = this._config.potentialAction.filter(function(potentialAction) {
          return potentialAction.name === name;
        });
      } else {
        action = this._config.potentialAction.find(function(potentialAction) {
          return potentialAction.name === name;
        });
      }
    }

    return action;
  },

  /** Place **/

  getGeo: function() {
    return this._config.geo;
  },

  /**
   * Override current Geo object with new one
   * @param {Object} geo the geo object to set
   * @returns {undefined}
   */
  setGeo: function(geo) {
    extend(this._config.geo, geo);
  },

  getAddress: function() {
    return this._config.address;
  },

  /**
   * Override current address object with new one
   * @param {Object} address the address object to set
   * @returns {undefined}
   */
  setAddress: function(address) {
    extend(this._config.address, address);
  },

  /**
   * Returns current map center
   * @returns {Object} the map center as a LngLatLike object as defined by Mapbox
   */
  getCenter: function() {
    var center = [
      this._config.geo.longitude,
      this._config.geo.latitude
    ];
    return this.copy(center);
  },

  /**
   * Returns default map zoom level
   * @returns {Integer} current configured zoom level
   */
  getZoom: function() {
    return this._config.geo.elevation;
  },

  /**
   * Returns the current geo box
   * @returns {Object} geo box
   */
  getBox: function() {
    var box = this._config.geo.box;
    return this.copy(box);
  },

  getBranchCode: function() {
    if (this._config.hasOwnProperty('branchCode')) {
      return this._config.branchCode;
    } else {
      return null;
    }
  }
};

module.exports = BaseConfig;
