/* global process, __dirname */
'use strict';

 // inlined by substack/brfs
var template = "<div class=\"row\">\n  <div id=\"list-panel\" class=\"col-xs-12 col-md-6 col-lg-5\"></div>\n  <h2 class=\"col-xs-12 hidden-sm hidden-md hidden-lg\">\n    <a class=\"dropdown-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#map-panel\" href=\"#map-panel\">\n      View Map\n      <span class=\"caret\"></span>\n    </a>\n  </h2>\n  <div id=\"map-panel\" class=\"col-xs-12 col-md-6 col-lg-7 collapse\"></div>\n</div>";

var baseSimple = {
  'potentialAction': [{
    'name': 'simpleLocator',
    'templateString': template,
    'compiledTemplate': undefined,
    'templateUrl': undefined
  }]
};

module.exports = baseSimple;
