'use strict';

var extend = require('extend');
var handlebars = (typeof window !== "undefined" ? window['Handlebars'] : typeof global !== "undefined" ? global['Handlebars'] : null);

/**
 * Removes all child object properties that do not have an '@type' property
 * @param  {Object} object - A schema.org object to clean
 * @param  {Int} limit - The maximum number of children arrays are allowed to have before removal
 * @return {Object} - A filtered clone of the original object
 */
function cleanByType(object, limit) {
  var cleaned;

  if (Array.isArray(object)) {
    cleaned = [];
  } else if (object && typeof object === 'object') {
    if (typeof object['@type'] === 'string') {
      cleaned = { };
    }
  } else {
    return object;
  }

  if (typeof cleaned !== 'undefined') {
    Object.keys(object).forEach(function(key) {
      if (key.slice(0, 1) !== '_') {
        var clean = cleanByType(object[key], limit);

        if (typeof clean !== 'undefined') {
          cleaned[key] = clean;
        }
      }
    });

    if (Array.isArray(cleaned)) {
      if (!cleaned.length && object.length || cleaned.length > limit) {
        return undefined;
      }
    }

    return cleaned;
  }
}

module.exports = function(object, limit, options) {
  if (typeof limit === 'undefined') {
    options = object;
    limit = 100;
    object = this;
  } else if (typeof options === 'undefined') {
    options = limit;
    limit = 100;
  }

  var cleaned = cleanByType(object, limit);

  if (typeof options.fn === 'function') {
    try {
      var override = JSON.parse(options.fn(this));

      extend(cleaned, override);  
    } catch (error) {
      // Do nothing
    }
  };

  return new handlebars.SafeString(JSON.stringify(cleaned));
};
