/* global process, __dirname */
'use strict';

 // inlined by substack/brfs
var template = "<div id='location-map'></div>\n<div id='location-search'></div>";
var popupTemplate = "<p>\n  <a href=\"{{url}}\">\n    <strong>\n      {{name}}\n    </strong>\n  </a>\n  <br />\n  {{address.streetAddress}} \n  <br />\n  {{address.addressLocality}}, {{address.addressRegion}} {{address.postalCode}}\n  <br />\n  {{telephone}}\n</p>\n<p>\n  <strong>Store Hours</strong>\n  {{{openingHours}}}\n</p>";

var baseMap = {
  'potentialAction': [{
    'name': 'locationMap',
    'accessCode': undefined || "pk.eyJ1IjoiZ2V0bG9jYWwiLCJhIjoiTUxnUXp2USJ9.5o-OWr98ISXCdfTdaSmGJQ",
    'url': 'mapbox://styles/mapbox/streets-v9',
    'geoRadius': undefined,
    'templateString': template,
    'compiledTemplate': undefined,
    'templateUrl': undefined
  }, {
    'name': 'mapTileset',
    'propertyID': 'locations',
    'url': undefined,
    'sourceLayer': 'original.geojson',
    'marker': 'marker',
    'markerSecondary': 'marker-24',
    'markerScale': 0.75,
    'markerFilter': undefined,
    'offset': [-24, -5],
    'zoom': 0.75,
    'markerZoom': undefined,
    'opacity': 1
  }, {
    'name': 'mapPopup',
    'propertyID': 'locations',
    'templateUrl': undefined,
    'templateString': popupTemplate,
    'compiledTemplate': undefined,
    'offset': [-4, -28]
  }, {
    'name': 'mapCluster',
    'color': '#F0A000',
    'strengthValue': 0.25,
    'geoRadius': 4,
    'maxZoom': 8
  }, {
    'name': 'geolocateMap',
    'enabled': false,
    'elevation': 10,
    'distance': undefined
  }, {
    'name': 'geocodeMap',
    'country': 'us',
    'types': 'country,region,postcode,locality,place,address',
    'placeholder': 'Search by City, State, or Zip',
    'zoom': 11,
    'geoRadius': undefined
  }]
};

module.exports = baseMap;
