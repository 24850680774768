/* global __dirname */
'use strict';

var extend = require('../util/extend');
var schemaUtil = require('./util');
var basePlace = require('./base-place');
var baseList = require('./base-list');
var BaseConfig = require('./base-config');
var TemplatedConfig = require('./templated-config');
var PaginatedConfig = require('./paginated-config');

var defaultConfig = schemaUtil.mergeConfigs([basePlace, baseList]);

/**
* A `ListConfig` is used to configure a [`LocationMap`](#locationmap) 
* component.  Utilizes an extended [`Map`](#map) schema and provides helper functions for working with.
* @param {Map} config map configuration object in JSON-LD format.
* @returns {MapConfig} copy of the current map config object
*/
var ListConfig = function ListConfig(config) {
  this._config = defaultConfig;
  if (config) {
    this.merge(config);
  }

  return this;
};

extend(ListConfig.prototype, BaseConfig);
extend(ListConfig.prototype, TemplatedConfig);
extend(ListConfig.prototype, PaginatedConfig);
extend(ListConfig.prototype, /** @lends ListConfig.prototype */ {
  // Additional list config methods
});

module.exports = ListConfig;
