'use strict';

/**
 * Methods mixed in to other components for event capabilities.
 *
 * @mixin EventEmitter
 */
var EventEmitter = {

  _JQInit: function() {
    this._JQ = jQuery(this._container);
  },

  /**
   * Adds a listener to a specified event type.
   * {@link https://api.jquery.com/on/|See jQuery API docs}
   * @param {string|Object} events One or more space-separated event types, or a JQuery event object
   * @param {Function|false} handler A function to execute when the event is triggered.
   * @returns {Object} `jQuery`
   */
  on: function(events, handler) {
    if (!this._JQ) { 
      this._JQInit(); 
    }
    this._JQ.on.apply(this._JQ, arguments);
    return this;
  },

  /**
   * Removes an event handler
   * {@link https://api.jquery.com/off/|See jQuery API docs}
   * @param {string} events One or more space-separated event types, or a JQuery event object
   * @param {Function|false} [handler] A handler function previously 
   * attached for the event(s), or the special value false
   * @returns {Object} `jQuery`
   */
  off: function(events, handler) {
    if (!this._JQ) { 
      this._JQInit(); 
    }
    this._JQ.off.apply(this._JQ, arguments);
    return this;
  },

  /**
   * Attach a handler to an event for the component.  The handler is executed 
   * once per event type.
   * {@link https://api.jquery.com/one/|See jQuery API docs}
   * @param {string} events One or more space-separated event types, or a JQuery event object
   * optional namespaces, such as "click" or "map.geocode"
   * @param {Function|false} handler A function to execute when the event is triggered.
   * @returns {Object} `jQuery`
   */
  one: function(events, handler) {
    if (!this._JQ) { 
      this._JQInit(); 
    }
    this._JQ.one.apply(this._JQ, arguments);
    return this;
  },

  /**
   * Execute all handlers and behaviors for the given event type.
   * {@link https://api.jquery.com/trigger/|See jQuery API docs}
   * @param {string} events One or more space-separated event types, or a JQuery event object
   * @param {Function|false} handler A function to execute when the event is triggered.
   * @returns {Object} jQuery
   */
  trigger: function(events, handler) {
    if (!this._JQ) { 
      this._JQInit(); 
    }
    this._JQ.trigger.apply(this._JQ, arguments);
    return this;
  }
};

module.exports = EventEmitter;
