/* global $, Handlebars */
'use strict';

(function($) {
  /**
   * Calculates paginate step number
   * @param {string} statement paginate statement
   * @param {Object} paginate paginate config object
   * @returns {integer} the step number
   */
  function calculatePaginate(statement, paginate) {
    if (typeof statement === 'string' && statement.length) {
      if (statement[0] === 'n') {
        return parseInt(statement.substr(1)) + parseInt(paginate.startPage);
      } else {
        var n = parseInt(statement);
        if (n < 0) {
          return parseInt(paginate.endPage) + 1 + n;
        } else {
          return n;
        }
      }
    } else {
      return 0;
    }
  }

  /**
   * Finds potentialAction by name
   * @param {Object} schema config object
   * @param {String} type potentialAction name
   * @returns {Object} the potentialAction
   */
  function action(schema, type) {
    var render;

    if (Array.isArray(schema.potentialAction)) {
      render = schema.potentialAction.find(function(theAction) {
        return theAction.name === type;
      });
    }

    return render;
  }

  /**
   * Render locations into template
   * @param {String} event event name
   * @param {Object} schema config object
   * @returns {undefined}
   */
  function renderSchema(event, schema) {
    schema = schema || $.data(this, 'plugin_schemaBars');

    var render;

    if (schema.compiledTemplate) {
      render = schema.compiledTemplate;
    } else {
      var body = schema.templateString;
      var jsonLdSchema = JSON.parse(JSON.stringify(schema));
      cleanJsonLdSchema(jsonLdSchema);
      body += '\n<script type="application/ld+json">\n' + JSON.stringify(jsonLdSchema) + '\n</script>';    
      render = Handlebars.compile(body);
    }

    $(this)
      .html(render(schema))
      .trigger('schemaBarsRendered', schema);
  }

  /**
   * Ensures an object only contains valid JSON-LD Schema
   * @param  {object} schema - A JSON-LD object
   * @return {boolean} True if the object is valid, false otherwise
   * @private
   */
  function cleanJsonLdSchema(schema) {
    if (Array.isArray(schema)) {
      for (var i = schema.length; i > -1; --i) {
        if (!cleanJsonLdSchema(schema[i])) {
          schema.splice(i, 1);
        }
      }

      return schema.length;
    } else if (typeof schema === 'object') {
      if (schema.hasOwnProperty('@type') && schema['@type'].indexOf('Configuration') === -1) {
        for (var key in schema) {
          if (typeof schema[key] === 'object') {
            if (!cleanJsonLdSchema(schema[key])) {
              delete schema[key];
            }
          }
        }
      } else {
        return false;
      }
    }

    return true;
  }

  /**
   * Downloads external schemabars template defined in config object and triggers render on load
   * @param {Object} schema config object
   * @param {*} element element to trigger render on once downloaded
   * @returns {undefined}
   */
  function downloadTemplate(schema, element) {
    if (schema.templateUrl && !schema.templateDownloaded) {
      $.get(schema.templateUrl, function(data) {
        schema.templateString = data;
        schema.templateDownloaded = true;
        element.trigger('schemaBarsRender', schema);
      });
    } else {
      element.trigger('schemaBarsRender', schema);
    }
  }

  $.fn.schemaBars = function(schema) {
    // Prevent multiple instantiation and reuse existing handlers, saving schema for later retrieval
    return this.each(function() {
      var plugEl = this;  // Save element reference
      var savedSchema = $.data(this, 'plugin_schemaBars');

      if (!savedSchema) {        
        var $this = $(this)
          .on('schemaBarsRender', renderSchema)
          .on('schemaBarsPaginate', renderSchema)
          .on('click', 'a[data-toggle]', function() {
            // Prevent changing of hash
            event.preventDefault();
            var theSchema = $.data(plugEl, 'plugin_schemaBars');

            var pageNum = parseInt($(this).attr('data-toggle'));
            if (isNaN(pageNum)) {
              return;
            }
            theSchema.startPage = pageNum;

            $this.trigger('schemaBarsPaginate', theSchema);
          });
      }

      $.data(this, 'plugin_schemaBars', schema);
      downloadTemplate(schema, $(this));
    });
  };
})(jQuery);
