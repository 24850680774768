/* global $ */
'use strict';

var extend = require('../util/extend');
var EventEmitter = require('../util/eventemitter');
var StorageMixin = require('../util/storage-mixin');
var SimpleLocator = require('./simple-locator');
var LocationMap = require('./location-map');
var LocationList = require('./location-list');

/**
 * The `LocationAgent` object represents an agent component capable of displaying
 * a saved location
 *
 * The `LocationAgent` object extends the [`LocationList`](#location-list) component.
 *
 * @param {Object} options Agent options
 * @param {HTMLElement|string} options.container The HTML element in which
 * this component will be rendered into, or the element's string `id`.  This
 * argument is provided automatically if invoked as a jQuery plugin
 * @param {ListConfig} options.config agent configuration object in JSON-LD format
 * @param {Object} options.place for setting the default place
 * @param {SimpleLocator|LocationList|LocationMap} options.source a locator, map, or list to monitor for place data
 * @returns {this} The LocationAgent object
 * @example
 * var agent = $('#agent').LocationAgent({ config: schema });
 * //or
 * var agent = new LocationAgent({
 *   container: 'agent',
 *   config: agentConfig,
 *   source: new SimpleLocator({
 *     container: 'locator',
 *     config: locatorConfig
 *   })
 * });
 */
var LocationAgent = function LocationAgent(options) {
  LocationList.bind(this)(options);

  this._session = this._sessionStorage();
  this._local = this._localStorage();

  var storagePlace = this.getPlace() || options.place;

  if (typeof storagePlace !== 'undefined' && storagePlace) {
    this.updatePlaces([storagePlace || options.place]);
  }

  this._source = options.source;
  if (!options.source) {
    this._error('Must pass source component of type SimpleLocator or LocationMap');
    return;
  }

  this._populate = this._populate.bind(this);
  this._geoPopulate = this._geoPopulate.bind(this);

  // Default location setter
  this._source.on('map.places.updated', this._populate);

  // Set users location if geolocation event
  this._source.on('map.geolocated', function() {
    // Switch to new location setter
    this._source.off('map.places.updated', this._populate);
    this._source.on('map.places.updated', this._geoPopulate);
  }.bind(this));

  // Set users location to new place selected in list
  this._source.on('list.place.selected', function(event, listEvent, place) {
    if (listEvent.target.getAttribute('agent-place')) {
      place.amenityFeature = place.amenityFeature || [];
      place.amenityFeature.push({
        'propertyID': 'agent-location',
        'name': 'My Location',
        'value': true
      });

      this.updatePlace(place);
    }
  }.bind(this));

  return this;
};

extend(LocationAgent.prototype, StorageMixin);
extend(LocationAgent.prototype, LocationList.prototype);
extend(LocationAgent.prototype, /** @lends LocationList.prototype */ {

  /**
   * Updates the place in a storage location, and triggers a view update
   * @param  {Object} place - A schema.org/Place compatible object
   * @return {undefined}
   */
  updatePlace: function(place) {
    if (typeof place === 'undefined') {
      return;
    }

    var selected = this.getPlace();
    // Check if user manually set location
    var isSelected = place && place.amenityFeature && place.amenityFeature.find(function(amenity) {
      return amenity.propertyID === 'agent-location' && amenity.value;
    });

    if (isSelected || selected && selected.branchCode === place.branchCode) {
      // Save to persistent local storage
      if (selected.branchCode !== place.branchCode) {
        this._local.setItem('agent.location', JSON.stringify(place));
      }
      this.trigger('agent.place.selected', [place]);
      this.updatePlaces([place]);
    } else {
      // Save to session storage
      this._session.setItem('agent.location', JSON.stringify(place));
      this.trigger('agent.place.selected', [place]);
      this.updatePlaces([place]);
    }
  },

  /**
   * Gets the user's currently tracked location
   * @return {Object} - A schema.org/Place compatible object
   */
  getPlace: function() {
    var storagePlace = this._local.getItem('agent.location') || this._session.getItem('agent.location') || 'false';
    return JSON.parse(storagePlace);
  },

  /**
   * Gets the type of storage being used for user's location
   * @return {String} - 'local' or 'session' or undefined
   * @private
   */
  getPlaceType: function() {
    var type;
    if (this._session.getItem('agent.location')) {
      type = 'session';
    }
    if (this._local.getItem('agent.location')) {
      type = 'local';
    }
    return type;
  },

  /**
   * Populates the user's selected place as soon as the map returns a result
   * @param  {Object} event - An EventEmitter or jQuery event
   * @param  {Array} places - The array of places shown on the map
   * @return {undefined}
   */
  _populate: function(event, places) {
    var selected = this.getPlace();

    if (selected) {
      this._source.off('map.places.updated', this._populate);
    } else if (places.length) {
      this.updatePlace(places[0]);
    }
  },

  /**
   * Populates the user's selected place as soon as the map returns a result after geolocated event
   * @param  {Object} event - An EventEmitter or jQuery event
   * @param  {Array} places - The array of places shown on the map
   * @return {undefined}
   */
  _geoPopulate: function(event, places) {
    var selected = this.getPlace();
    var type = this.getPlaceType();

    if (!places) {
      return;  // Wait for event with places
    }

    // if not selected or session storage then update
    if (!selected || type === 'session') {
      this._source.off('map.places.updated', this._geoPopulate);
      this.updatePlace(places[0]);
    }
  },

  /**
   * Fired after a place is selected
   *
   * @event agent.place.selected
   * @memberof LocationAgent
   * @instance
   * @type {Object}
   * @property {Place} [place] the selected place
   */

  /**
   * Fired when an error occurs.  Primary error reporting mechanism
   *
   * @event agent.error
   * @memberof LocationAgent
   * @instance
   * @type {Object}
   * @param {{error: {message: string}}} error event object or name
   * @returns {undefined}
   */
  _error: function(error) {
    // ToDo - check for handler and if not one then throw as normal
    var errObj;
    if (typeof error === 'string') {
      errObj = { error: new Error(error) };
    } else if (error instanceof Error) {
      errObj = error;
    }
    errObj.type = 'agent';
    this.trigger('error', errObj);
  }
});

module.exports = LocationAgent;
