/* global $, Handlebars */
'use strict';

var extend = require('../util/extend');
var EventEmitter = require('../util/eventemitter');
var ListConfig = require('../schema/list-config');

var defaultOptions = {
  container: undefined,
  listContainer: 'location-list',
  places: undefined
};

/**
 * The `LocationList` object represents a list component capable of displaying
 * locations.
 *
 * The `LocationList` object mixes in [`EventEmitter`](#eventemitter) methods.
 *
 * @param {Object} options List options
 * @param {HTMLElement|string} options.container The HTML element in which
 * this component will be rendered into, or the element's string `id`.  This
 * argument is provided automatically if invoked as a jQuery plugin
 * @param {ListConfig} options.config list configuration object in JSON-LD format
 * @param {Array} options.places array of places, mainly used for testing
 * @returns {this} The LocationList object
 * @example
 * var lmap = $('#map').LocationList(schema);
 * //or
 * var lmap = new LocationList({
 *   container: 'list',
 *   config: listConfig,
 * });
 */
var LocationList = function LocationList(options) {
  options = extend({}, defaultOptions, options);

  if (!options.container) {
    this._error('Must pass container ID or DOM element');
  }
  if (typeof options.container === 'string') {
    this._container = window.document.getElementById(options.container);
  } else {
    this._container = options.container;
  }

  $(this._container).on('click', '[action-view-location], [place-id]', function(event) {
    var placeId;
    placeId = event.currentTarget.getAttribute('action-view-location');
    if (!placeId) {
      placeId = event.currentTarget.getAttribute('place-id');
    }

    if (placeId) {
      var place = this._placeList.itemListElement.find(function(thePlace) {
        return thePlace.branchCode == placeId;  // eslint-disable-line eqeqeq
      });

      if (place) {
        this.trigger('list.place.viewed', [event, place]);
      }
    }
  }.bind(this));

  this._listContainer = options.listContainer;
  this._config = new ListConfig();

  if (options.config) {
    this._config.merge(options.config, 'locationList');
  }

  var defaultPlaceList;
  if (options.places) {
    defaultPlaceList = {
      numberOfItems: options.places.length,
      itemListElement: options.places
    };
    this.updatePlaces(defaultPlaceList);
  } else {
    defaultPlaceList = {
      numberOfItems: -1,
      itemListElement: []
    };
    this.loadingPlaces(defaultPlaceList);
  }

  return this;
};

extend(LocationList.prototype, EventEmitter);
extend(LocationList.prototype, /** @lends LocationList.prototype */ {

  /**
   * Update the components config and template, and re-render the list
   * @param {Object} options List options
   * @param {Map} options.config map configuration object in JSON-LD format.
   * @returns {Promise} update promise
   */
  update: function(options) {
    if (options) {
      this._config.merge(options.config, 'locationList');
    }
    return this._updateTemplate().then(function() {
      this._render();
    }.bind(this));
  },

  loadingPlaces: function(placeList) {
    this._placeList = placeList;
    return this.update();
  },

  updatePlaces: function(placeList) {
    if (!placeList || !placeList.itemListElement || placeList.numberOfItems < 0) {
      return;
    }

    this._placeList = placeList;

    if (this._config.isPaginated('locationList')) {
      var endPage = Math.ceil(this._placeList.itemListElement.length / this._config.getPerPage('locationList'));
      this._config.setEndPage('locationList', endPage);
    }

    return this.update();
  },

  setActivePlace: function(id) {
    $('li[place-id]').removeClass('active');
    $("li[place-id='" + id + "']").addClass('active');
  },

  clearActivePlace: function(id) {
    $('li[place-id]').removeClass('active');
  },

  /*
   * Check for new template, fetch if needed
   * @returns {Promise} promise resolved once template loaded
   * @private
   */
  _updateTemplate: function() {
    var deferred = $.Deferred();
    var configStr = this._config.getTemplateString('locationList');
    var configURL = this._config.getTemplateURL('locationList');

    // If template string not yet loaded in config, fetch and update from URL
    if (!configStr) {
      if (!configURL) {
        this._error('Missing template string or template URL');
        return deferred.reject();
      }
      $.get(configURL, function(source) {
        this._config.setTemplateString(source, 'locationList');
        return deferred.resolve();
      }.bind(this), 'html');
      return deferred.promise();
    }

    deferred.resolve();
    return deferred.promise();
  },

  /**
   * Render the component
   * @private
   * @returns {undefined}
   */
  _render: function() {
    var schema = this._config.getConfig('locationList');
    var placeListCopy = $.extend(true, schema, this._placeList);
    $(this._container).schemaBars(placeListCopy);
  },

  _init: function() {
    if (typeof this._listContainer === 'string') {
      this._listContainer = window.document.getElementById(this._listContainer);
    }
    this.inited = true;
  },

   /**
   * Fired after list item clicked
   *
   * @event list.place.viewed
   * @memberof LocationList
   * @instance
   * @type {Object}
   * @property {Object} domEvent Element click event Object
   * @property {Place} [place] the selected place
   */

   /**
   * Fired when an error occurs.  Primary error reporting mechanism
   *
   * @event list.error
   * @memberof LocationList
   * @instance
   * @type {Object}
   * @param {{error: {message: string}}} error error object or string
   * @returns {undefined}
   */
  _error: function(error) {
    // ToDo - check for handler and if not one then throw as normal
    var errObj;
    if (typeof error === 'string') {
      errObj = { error: new Error(error) };
    } else if (error instanceof Error) {
      errObj = error;
    }
    errObj.type = 'list';
    this.trigger('error', errObj);
  }
});

module.exports = LocationList;

