/* global $ */
'use strict';

var MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');
var MapboxClient = require('mapbox/lib/services/geocoding');
var extend = require('../util/extend');
var Typeahead = require('suggestions');

/**
 * The `LocationGeocoder` object represents a geocoder element including a
 * suggestion list.  This object is typically not used directly.  A
 * [`LocationMap`](#createGeocoder) factory method is available.
 *
 * The `LocationGeocoder` extends the `mapbox-gl-geocoder` plugin
 *
 * @param {Object} options Geocoder options - See Mapbox geocoder docs
 * @param {string} options.accessToken The Mapbox API key
 * @param {string} options.country lowercase country code
 * @param {string} options.types types of places to include
 * @param {string} options.placeholder the input placeholder text
 * @returns {this} The LocationGeocoder object
 */
function LocationGeocoder(options) {
  MapboxGeocoder.call(this, options); // Call super constructor
  this.getContainer = this.getContainer.bind(this);
  return this;
}

LocationGeocoder.prototype = Object.create(MapboxGeocoder);
extend(LocationGeocoder.prototype, MapboxGeocoder.prototype);
extend(LocationGeocoder.prototype, /** @lends LocationGeocoder.prototype */ {

  /**
   * Initializes geocoder component and binds to MapboxGL map instance
   * @param {Object} map - MapboxGL instance
   * @returns {HTMLElement} the geocoder DOM element
   */
  onAdd: function(map) {
    this._map = map;
    this.mapboxClient = new MapboxClient(this.options.accessToken);
    this._onChange = this._onChange.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
    this._onQueryResult = this._onQueryResult.bind(this);
    this._clear = this._clear.bind(this);
    this._search = this._search.bind(this);

    var el = this.container = document.createElement('div');
    el.className = 'mapboxgl-ctrl-geocoder mapboxgl-ctrl';

    this._inputEl = document.createElement('input');
    this._inputEl.type = 'text';
    this._inputEl.placeholder = this.options.placeholder;

    this._inputEl.addEventListener('keydown', this._onKeyDown);
    this._inputEl.addEventListener('change', this._onChange);

    var actions = document.createElement('div');

    var inputActions = document.createElement('span');
    inputActions.classList.add('geocoder-pin-right');
    actions.appendChild(inputActions);

    this._clearEl = document.createElement('button');
    this._clearEl.className = 'geocoder-icon geocoder-icon-close';
    this._clearEl.addEventListener('click', this._clear);
    inputActions.appendChild(this._clearEl);

    this._loadingEl = document.createElement('span');
    this._loadingEl.className = 'geocoder-icon geocoder-icon-loading';
    inputActions.appendChild(this._loadingEl);

    var searchAction = document.createElement('span');
    searchAction.classList.add('geocoder-pin-far-right');
    actions.appendChild(searchAction);

    this._searchEl = document.createElement('button');
    this._searchEl.classList.add('btn-search');
    this._searchEl.type = 'button';
    this._searchEl.innerHTML = 'GO';
    // this._searchEl.className = 'geocoder-icon geocoder-icon-close';
    this._searchEl.addEventListener('click', this._search);
    searchAction.appendChild(this._searchEl);

    el.appendChild(this._inputEl);
    el.appendChild(actions);

    this._typeahead = new Typeahead(this._inputEl, [], { filter: false });
    this._typeahead.getItemValue = function(item) {
      return item.place_name;
    };

    return el;
  },

  _search: function() {
    this._typeahead.handleKeyDown({ keyCode: 13 });
  },

  getContainer: function() {
    return this.container;
  }
});

module.exports = LocationGeocoder;

