'use strict';

/* 
 * This is the entry module for the getlocal Locator library
 * It defines the top-level API and defines any globals
 * making this library and certain dependencies directly
 * accessible.
 * 
 * Browserify is used to transform and bundle this
 * into a standalone JS file that can be run in the browser.
 */

// Polyfills
require('core-js/fn/array/find');
require('core-js/fn/array/find-index');

// Register dependencies as globals, use external if available in some cases.
// otherwise require directly.  Note using global is bit of an anti-pattern

// Check if debug and set global
if (process.env.DEBUG) {
  global.__DEBUG__ = process.env.DEBUG;
} else {
  global.__DEBUG__ = false;
}

// JQuery
if (!window || !window.jQuery) {
  global.$ = global.jQuery = require('jquery');
} else {
  global.$ = global.jQuery = window.jQuery;
}

// Handlebars
if (!window || !window.Handlebars) {
  global.Handlebars = require('handlebars');
} else {
  global.Handlebars = window.Handlebars;
}

// Add helpers
var helpers = require('schemabars-helpers');
Object.keys(helpers).forEach(function(helper) {
  window.Handlebars.registerHelper(helper, helpers[helper]);
});

// MapboxGL, expected by Geocoder plugin
global.mapboxgl = require('mapbox-gl/src');

// Create library API
var locator = {};
locator.version = require('../package.json').version;
locator.LocationMap = require('./ui/location-map');
locator.LocationList = require('./ui/location-list');
locator.LocationAgent = require('./ui/location-agent');
locator.SimpleLocator = require('./ui/simple-locator');
locator.AdvancedLocator = require('./ui/advanced-locator');

// Register components as jQuery plugins
var createPlugin = require('./util/jquery').createPlugin;
createPlugin('LocationMap', locator.LocationMap);
createPlugin('LocationList', locator.LocationList);
createPlugin('LocationAgent', locator.LocationAgent);
createPlugin('SimpleLocator', locator.SimpleLocator);
createPlugin('AdvancedLocator', locator.AdvancedLocator);

// Register standalone jQuery plugins
require('./ui/schema-bars');

// Attach locator to getlocal namespace on window if browser environment
if (window) {
  if (!window.getlocal) {
    window.getlocal = {};
  }
  window.getlocal.locator = locator;
} else if (typeof module !== 'undefined') {
  module.exports = locator;
}
