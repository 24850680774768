'use strict';

module.exports = function(list, options) {
  var config = options.data.root;
  var pagination = parseInt(config.pagination);
  var startItem = parseInt(config.startPage - 1) * pagination;
  var endItem = startItem + pagination;

  return list.slice(startItem, endItem).map(function(context) {
    return options.fn(context);
  })
  .join('');
};
