'use strict';

/* global Handlebars */

var extend = require('../util/extend');
var EventEmitter = require('../util/eventemitter');
var StorageMixin = require('../util/storage-mixin');
var SimpleConfig = require('../schema/simple-config');

var LocationMap = require('./location-map');
var LocationList = require('./location-list');

var defaultOptions = {
  container: undefined,
  listContainer: 'list-panel',
  mapContainer: 'map-panel'
};

/**
 * The `SimpleLocator` components provides a basic Locator experience
 * consisting of a LocationMap and LocationList component allowing a user to
 * discover and select a location.
 *
 * The `SimpleLocator` object mixes in [`EventEmitter`](#eventemitter) methods.
 *
 * @param {Object} options Locator options
 * @param {HTMLElement|string} options.container The HTML element in which
 * this component will be rendered into, or the element's string `id`.  This
 * argument is provided automatically if invoked as a jQuery plugin
 * @param {SimpleConfig} options.config SimpleLocator config object
 * @returns {SimpleLocator} UI component
 * @example
 * var lmap = $('#map').SimpleLocator({config: config});
 * //or
 * var lmap = new SimpleLocator({
 *   container: 'locator',
 *   config: config,
 * });
 */
var SimpleLocator = function SimpleLocator(options) {
  options = extend({}, defaultOptions, options);

  if (!options.container) {
    this._error('Must pass container ID or DOM element');
    return;
  }
  if (typeof options.container === 'string') {
    this._container = window.document.getElementById(options.container);
  } else {
    this._container = options.container;
  }

  $(this._container).on('click', '[action-select-location]', this._onPlaceSelected.bind(this));

  this._container = options.container;
  this._listContainer = options.listContainer;
  this._mapContainer = options.mapContainer;

  this._config = new SimpleConfig(options.config);
  this._session = this._sessionStorage();
  this._session.setItem('place.selected', '');
  this._session.setItem('place.viewed', '');

  this.update(options);

  return this;
};

extend(SimpleLocator.prototype, EventEmitter);
extend(SimpleLocator.prototype, StorageMixin);
extend(SimpleLocator.prototype, /** @lends SimpleLocator.prototype */ {

  /**
   * Update the component with new options
   * @param {Object} options Map options
   * @param {Map} options.config locator configuration object in JSON-LD format.
   * @returns {undefined}
   */
  update: function(options) {
    this._config.merge(options.config);
    return this._updateTemplate().then(function() {
      this._render();
    }.bind(this));
  },

  /**
   * Factory function to create `LocationGeocoder` and return DOM element
   * @returns {HTMLElement} geocoder DOM element, rendered and ready to be added to page
   */
  createGeocoder: function() {
    return this._map.createGeocoder();
  },


  /**
   * Returns the Mapbox GL JS {@link https://www.mapbox.com/mapbox-gl-js/api/#Map|Map} object
   * @returns {Object} The Mapbox GL JS map
   */
  getMap: function() {
    return this._map.getMap();
  },

  /*
   * Check for new template, fetch if needed
   * @returns {Promise} promise resolved once template loaded
   * @private
   */
  _updateTemplate: function() {
    var deferred = $.Deferred();
    var templateString = this._config.getTemplateString('simpleLocator');
    var compiledTemplate = this._config.getcompiledTemplate('simpleLocator');
    var templateUrl = this._config.getTemplateURL('simpleLocator');

    // Load from URL
    if (templateUrl && !this.templateDownloaded) {
      $.get(templateUrl, function(source) {
        this.templateDownloaded = true;
        this._config.setTemplateString('simpleLocator', source);
        this._templateString = source;
        this._template = Handlebars.compile(source);
        return deferred.resolve();
      }.bind(this), 'html');
      return deferred.promise();
    }

    if (compiledTemplate) {
      // Load precompiled
      this._template = compiledTemplate;
      deferred.resolve();
    } else if (this._templateString !== templateString) { // eslint-disable-line no-negated-condition
      // Load from string, update if changed
      this._templateString = templateString;
      this._template = Handlebars.compile(templateString);
    } else {
      this._error('Missing template config');
      return deferred.reject();
    }
    deferred.resolve();

    return deferred.promise();
  },

  /**
   * Render the component
   * @returns {SimpleLocator} this
   * @private
   */
  _render: function() {
    // Render container
    this._container.innerHTML = this._template();

    if (!this._lmap) {
      this._init();
    }

    return this;
  },

  /*
   * Initialize sub-components and handle or relay their events accordingly
   * @returns {undefined}
   * @private
   */
  _init: function() {
    var config = this._config.getConfig();

    this._map = new LocationMap({
      container: this._mapContainer,
      config: config
    });

    this._list = new LocationList({
      container: this._listContainer,
      config: config
    });

    this._map.on('map.places.updated', this._onPlaceUpdate.bind(this));
    this._map.on('map.loaded', function(event) {
      this.trigger('simple.loaded');
    }.bind(this));
    this._map.on('map.geolocated', function(event, position) {
      this.trigger('simple.geolocated', position);
    }.bind(this));
    this._map.on('map.geolocate.error', function(event, position) {
      this.trigger('simple.geolocated.error', position);
    }.bind(this));
    this._map.on('map.geocoded', function(event, result) {
      this.trigger('simple.geocoded', result);
    }.bind(this));

    this._map.on('map.moving', this._onMapMoving.bind(this));
    this._map.on('map.moved', this._onMapMoved.bind(this));
    this._map.on('map.place.hovered', function(event, place) {
      this.trigger('simple.place.hovered', place);
    }.bind(this));
    this._map.on('map.popup.opened', this._onPopupOpened.bind(this));
    this._map.on('map.popup.closed', this._onPopupClosed.bind(this));

    this._list.on('list.place.viewed', this._onPlaceViewed.bind(this));
  },

  _onMapMoving: function(event) {
    this.trigger('simple.map.moving');
    this.trigger('simple.map.moved');
    // Fix the list height
    var listEl = $('#' + this._listContainer);
    this.listHeight = $(listEl).css('height');
    listEl.css('height', this.listHeight);
    // Set list loading
    this._list.loadingPlaces({
      itemListElement: this._places,
      numberOfItems: -1 // Indicates loading
    });
  },

  _onMapMoved: function() {
    this.trigger('simple.map.moved');
    this._list.loadingPlaces({
      itemListElement: [],
      numberOfItems: -1 // Indicates loading
    });
  },

  _onPlaceUpdate: function(event, places) {
    this.trigger('simple.places.updated', [places]);
    this._places = places;
    this._list.updatePlaces({
      itemListElement: places,
      numberOfItems: places.length
    }).then(function() {
      // Unfix the list height
      if (this.listHeight) {
        var listEl = $('#' + this._listContainer);
        listEl.removeAttr('height').css({ height: '' });
        this.listHeight = undefined;
      }
    }.bind(this));
  },

  _onPlaceViewed: function(event, listEvent, place) {
    this.trigger('simple.place.viewed', place);
    this.viewedID = place.branchCode;
    this._map.showPlace(place, listEvent);
    this._session.setItem('place.viewed', JSON.stringify(place));
  },

  _onPlaceSelected: function(event) {
    var placeId;
    placeId = event.currentTarget.getAttribute('action-select-location');
    if (!placeId) {
      placeId = event.currentTarget.getAttribute('place-id');
    }

    if (placeId) {
      var place = this._placeList.itemListElement.find(function(thePlace) {
        return thePlace.branchCode == placeId;  // eslint-disable-line eqeqeq
      });
      this.trigger('simple.place.selected', place);
      this.selectedID = place.branchCode;
      this._session.setItem('place.selected', JSON.stringify(place));
    }
  },

  _onPopupOpened: function(event, mapEvent, place) {
    // Ignore event if ID already set
    if (this.selectedID === place.branchCode) {
      return;
    }
    this.selectedID = place.branchCode;
    this._session.setItem('place.viewed', JSON.stringify(place));
    this.trigger('simple.popup.opened', [mapEvent, place]);
  },

  _onPopupClosed: function(event, place) {
    // Only clear if another marker hasn't already been selected (and popup opened)
    if (this.selectedID === place.branchCode) {
      this.selectedID = undefined;
      this._session.removeItem('place.viewed');
      this._list.update();
    }
  },

   /**
   * Fired when an error occurs.  Primary error reporting mechanism
   *
   * @event simple.error
   * @memberof SimpleLocator
   * @instance
   * @type {Object}
   * @param {{error: string}|String} error error object or string
   * @returns {undefined}
   */
  _error: function(error) {
    // ToDo - check for handler and if not one then throw as normal
    var errObj;
    if (typeof error === 'string') {
      errObj = { error: new Error(error) };
    } else if (error instanceof Error) {
      errObj = error;
    }
    errObj.type = 'simple';
    this.trigger('error', errObj);
  }

  /**
   * Fired after locator loaded
   *
   * @event simple.loaded
   * @memberof SimpleLocator
   * @instance
   * @type {Object}
   * @return {undefined}
   */

   /**
   * Fired after geocoder result received
   *
   * @event simple.geocoded
   * @memberof SimpleLocator
   * @instance
   * @type {Object}
   * @property {Object} result Mapbox geocoder result
   */

   /**
   * Fired after locations updated
   *
   * @event simple.places.updated
   * @memberof SimpleLocator
   * @instance
   * @type {Object}
   * @property {Array} loctions An array of schema.org/LocalBusiness for each
   * location visible in the map viewport
   */

   /**
   * Fired after map clicked
   *
   * @event simple.place.selected
   * @memberof SimpleLocator
   * @instance
   * @type {Object}
   * @property {Object} mapEvent Mapbox map click event Object
   * @property {Place} [place] the selected place
   */

   /**
   * Fired after place hovered
   *
   * @event simple.place.hovered
   * @memberof SimpleLocator
   * @instance
   * @type {Object}
   * @property {LocalBusiness} place the place hovered
   */

   /**
   * Fired after popup opened
   *
   * @event simple.popup.opened
   * @memberof SimpleLocator
   * @instance
   * @type {Object}
   * @property {Object} mapEvent Mapbox map click event Object
   * @property {Place} place the place popup opened for
   */
});

module.exports = SimpleLocator;
