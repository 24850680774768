'use strict';

var url = require('url');
var handlebars = (typeof window !== "undefined" ? window['Handlebars'] : typeof global !== "undefined" ? global['Handlebars'] : null);

module.exports = function(object, path, options) {
  if (typeof path === 'undefined') {
    options = object;
    object = options.data.root;
  } else if (typeof options === 'undefined') {
    options = path;
    path = undefined;
  }

  var renderHTML = object.potentialAction.find(function(action) {
    return action.name === 'renderHTML'
  });

  if (typeof path === 'undefined') {    
    path = object.url;
  } else if (typeof path === 'object') {
    path = path.url;
  }

  return new handlebars.SafeString(url.resolve(renderHTML.url, path));
};
