'use strict';

// install ES6 Promise polyfill
require('./promise');

var rest = require('rest');

// rest.js client with MIME support
module.exports = function(config) {
  return rest
    .wrap(require('rest/interceptor/errorCode'))
    .wrap(require('rest/interceptor/pathPrefix'), { prefix: config.endpoint })
    .wrap(require('rest/interceptor/mime'), { mime: 'application/json' })
    .wrap(require('rest/interceptor/template'))
    .wrap(require('rest/interceptor/defaultRequest'), {
      params: { access_token: config.accessToken }
    })
    .wrap(require('./paginator'), { access_token: config.accessToken })
    .wrap(require('./standard_response'))
    .wrap(require('./callbackify'));
};
