'use strict';
var extend = require('../util/extend');

/**
 * Geo utility functions
 *
 * @mixin Geo
 */

var Geo = {
  /**
   * Calculates the distance between two points in kilometers
   * @param  {Object} a - A schema.org/Place with a geo property
   * @param  {Object} b - A schema.org/Place with a geo property
   * @return {Number} The distance in kilometers
   */
  haversineDistance: function(a, b) {
    var haversine = Math.pow(Math.sin((a.geo.latitude - b.geo.latitude) * Math.PI / 180), 2) +
      Math.pow(Math.sin((a.geo.longitude - b.geo.longitude) * Math.PI / 180), 2) *
      Math.cos(a.geo.latitude * Math.PI / 180) *
      Math.cos(b.geo.latitude * Math.PI / 180);

    return 12742 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));
  },

  /**
   * Given a bounding box and GL map object, returns the center
   * point of that box and the highest zoom level up to and
   * including maxZoom that fits them in the viewport.
   * @param {Map} map - mapboxgl map object
   * @param {Array} bounds - LngLatBoundsLike array
   * @param {Object} [options]
   * @param {number} [options.padding=0] The amount of padding, in pixels, to allow around the specified bounds.
   * @param {PointLike} [options.offset=[0, 0]] The center of the given bounds relative to the map's center, measured in pixels.
   * @param {number} [options.maxZoom] The maximum zoom level to allow when the map view transitions to the specified bounds.
   */
  convertBounds: function(map, bounds, options) {
    options = extend({
      padding: 0,
      offset: [0, 0],
      maxZoom: map.getMaxZoom()
    }, options);

    bounds = mapboxgl.LngLatBounds.convert(bounds);

    var offset = mapboxgl.Point.convert(options.offset),
      tr = map.transform,
      nw = tr.project(bounds.getNorthWest()),
      se = tr.project(bounds.getSouthEast()),
      size = se.sub(nw),
      scaleX = (tr.width - options.padding * 2 - Math.abs(offset.x) * 2) / size.x,
      scaleY = (tr.height - options.padding * 2 - Math.abs(offset.y) * 2) / size.y;

    var center = tr.unproject(nw.add(se).div(2));
    var zoom = Math.min(tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)), options.maxZoom);

    return {
      center: center,
      zoom: zoom
    };
  }
};

module.exports = Geo;
