'use strict';

var extend = require('extend');
var handlebars = (typeof window !== "undefined" ? window['Handlebars'] : typeof global !== "undefined" ? global['Handlebars'] : null);
var objectPath = require('object-path');
var changeCase = require('change-case');

module.exports = function(path, article, options) {
  var data;
  var things = objectPath.get(options.data.root, path);

  if (options.data) {
    data = handlebars.createFrame(options.data);
  }

  return things.map(function(thing) {
    return thing.category;
  }).filter(function(thing, position, map) {
    return map.indexOf(thing) === position;
  }).map(function(category, i, categories) {
    var context = extend(true, { }, options.data.root);
    var index = changeCase.paramCase(article.replace(/\-/, category + '-'));
    var key = changeCase.paramCase(category);

    objectPath.set(context, path, things.filter(function(thing) {
      return thing.category === category;
    }));

    if (context.articleBody && typeof context.articleBody[index] === 'string') {
      context.articleBody[article] = context.articleBody[index];
    }

    if (data) {
      data.first = i === 0;
      data.index = i;
      data.key = key;
      data.last = i === categories.length;
    }

    return options.fn(context, { 'data': data });
  }).join('');
}