/* global process, __dirname */
'use strict';

var extend = require('../util/extend');
var schemaUtil = require('./util');
var basePlace = require('./base-place');
var baseMap = require('./base-map');
var BaseConfig = require('./base-config');
var TemplatedConfig = require('./templated-config');

var defaultConfig = schemaUtil.mergeConfigs([basePlace, baseMap]);

/**
* A `MapConfig` is used to configure a [`LocationMap`](#locationmap)
* component.  Utilizes an extended [`Map`](#map) schema and provides helper functions for working with.
* @param {Map} config map configuration object in JSON-LD format.
* @returns {MapConfig} copy of the current map config object
*/
var MapConfig = function MapConfig(config) {
  this._config = defaultConfig;
  if (config) {
    this.merge(config);
  }

  return this;
};

extend(MapConfig.prototype, BaseConfig);
extend(MapConfig.prototype, TemplatedConfig);
extend(MapConfig.prototype, /** @lends MapConfig.prototype */ {

  /**
   * Returns copy of underlying map configuration object.
   * @returns {Map} a copy of the current map object
   */
  getMapConfig: function() {
    var config = this.findAction('locationMap');
    return this.copy(config);
  },

  /**
   * Returns copy of underlying map layer configuration object.
   * @returns {Object} a copy of the current layer configs
   */
  getLayerConfigs: function() {
    var config = this.findAction('mapTileset', true);
    return this.copy(config);
  },

  getBranchCode: function() {
    if (this._config.hasOwnProperty('branchCode')) {
      return this._config.branchCode;
    } else {
      return null;
    }
  },

  /**
   * Returns minimum zoom level to set map to when marker is clicked
   * @returns {number} a zoom level value from 0 to 22
   */
  getMarkerZoom: function() {
    var config = this.findAction('mapTileset');
    return config.markerZoom;
  },

  /**
   * Returns Mapbox API access token
   * @returns {string} the configured Mapbox API access token
   */
  getAccessToken: function() {
    return this.findAction('locationMap').accessCode;
  },

  /**
   * Returns style URL
   * @returns {string} the configured Mapbox style URL
   */
  getStyleURL: function() {
    return this.findAction('locationMap').url;
  },

  /**
   * Returns true if Geo is set to default value
   * @returns {Boolean} whether geo has been overridden or not
   */
  hasDefaultGeo: function() {
    return (
      this.getGeo().latitude === defaultConfig.geo.latitude &&
      this.getGeo().longitude === defaultConfig.geo.longitude
    );
  },

  /**
   * Returns geo radius for Mapbox Geocoder
   * @return {Number} radius
   */
  getMapRadius: function() {
    return this.findAction('locationMap').geoRadius;
  },

  /**
   * Returns configuration for Mapbox Geocoder
   * @return {Action} render map config action
   */
  getGeocoderConfig: function() {
    var geocoderConfig = this.findAction('geocodeMap');
    return this.copy(geocoderConfig);
  },

  /**
   * Returns geo radius for Mapbox Geocoder
   * @return {Number} radius
   */
  getGeocoderRadius: function() {
    return this.findAction('geocodeMap').geoRadius;
  },

  getGeocoderZoom: function() {
    return this.findAction('geocodeMap').zoom;
  },

  /**
   * Returns configuration for geolocation
   * @return {Action} map geolocation action
   */
  getGeolocationConfig: function() {
    var geolocConfig = this.findAction('geolocateMap');
    return this.copy(geolocConfig);
  },

  /**
   * Returns configuration for Mapbox GL popup
   * @return {Action} render popup config action
   */
  getPopupConfig: function() {
    var popupConfig = this.findAction('mapPopup');
    return this.copy(popupConfig);
  },

  /**
   * Returns current popup template string.
   * @return {String} current popup template string
   */
  getPopupString: function() {
    return this.getPopupConfig().templateString;
  },

  /**
   * Updates popup template string
   * @param {string} value html string value
   * @return {undefined}
   */
  setPopupString: function(value) {
    this.getPopupConfig().templateString = value;
  },

  /**
   * Returns current popup template URL. undefined by default
   * @return {URL} popup template URL
   */
  getPopupURL: function() {
    return this.getPopupConfig().templateUrl;
  },

  /**
   * Returns configuration for marker clustering
   * @return {Action} render cluster config action
   */
  getClusterConfig: function() {
    var clusterConfig = this.findAction('mapCluster');
    return this.copy(clusterConfig);
  }
});

module.exports = MapConfig;
