'use strict';

module.exports = function(name, options) {
  var action = options.data.root.potentialAction.find(function(potentialAction) {
    return potentialAction.name === name;
  });

  if (action) {
    return options.fn(action);
  } else {
    return options.inverse(this);
  }
};
