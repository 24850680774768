/* global __dirname */
'use strict';

var extend = require('../util/extend');
var schemaUtil = require('./util');
var basePlace = require('./base-place');
var baseSimple = require('./base-simple');
var baseMap = require('./base-map');
var baseList = require('./base-list');
var BaseConfig = require('./base-config');
var TemplatedConfig = require('./templated-config');

/* Simple's sub-component configs (ItemList, Map) go under the hasPart array property
 * and their default values are left to be defined and maintained by their
 * config classes. When instantiating this component, if the developer wants to customize
 * the sub-components they'll need to provide their config objects under the hasPart Array property.
 */

var defaultConfig = schemaUtil.mergeConfigs([basePlace, baseSimple, baseMap, baseList]);

/**
* A `SimpleConfig` is used to configure a [`SimpleLocator`](#simplelocator)
* component.  Utilizes a [`WebPageElement`](#webpageelement) schema and
* provides helper functions for working with.
* @param {WebPageElement} config map configuration object in JSON-LD format.
* @returns {SimpleConfig} copy of the current map config object
*/
var SimpleConfig = function SimpleConfig(config) {
  this._config = defaultConfig;
  if (config) {
    this.merge(config);
  }

  return this;
};

extend(SimpleConfig.prototype, BaseConfig);
extend(SimpleConfig.prototype, TemplatedConfig);
extend(SimpleConfig.prototype, /** @lends SimpleConfig.prototype */ {});

module.exports = SimpleConfig;
