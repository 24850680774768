'use strict';

var schemaUtil = require('./util');

/**
* The `PaginateConfig` mixin provides methods for working with pagination config
* @mixin PaginatedConfig
*/
var PaginatedConfig = {
  isPaginated: function(action) {
    var listConfig = schemaUtil.findAction(action, this._config);
    return listConfig.hasOwnProperty('pagination');
  },

  getPerPage: function(action) {
    return schemaUtil.findAction(action, this._config).pagination;
  },

  setStartPage: function(action, page) {
    var listConfig = schemaUtil.findAction(action, this._config);
    listConfig.startPage = page;
  },

  setEndPage: function(action, page) {
    var listConfig = schemaUtil.findAction(action, this._config);
    listConfig.endPage = page;
  }
};

module.exports = PaginatedConfig;
