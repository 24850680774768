'use strict';

/**
* The `TemplatedConfig` mixin provides methods for working with templates
* @mixin TemplatedConfig
*/
var TemplatedConfig = {
  /**
   * Returns current template string.
   * @return {String} current template string
   */
  getTemplateString: function(action) {
    return this.findAction(action).templateString;
  },

  /**
   * Updates template string
   * @param {String} value - Handlebars template string
   * @return {undefined}
   */
  setTemplateString: function(action, value) {
    var config = this.findAction(action);
    config.templateString = value;
  },

  /**
   * Returns precompiled Handlebars template funcion, if available. undefined by default
   * @return {function} template function
   */
  getcompiledTemplate: function(action) {
    return this.findAction(action).compiledTemplate;
  },

  /**
   * Returns current template URL. undefined by default
   * @return {URL} template URL
   */
  getTemplateURL: function(action) {
    return this.findAction(action).templateUrl;
  }
};

module.exports = TemplatedConfig;
