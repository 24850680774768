'use strict';

var formatHours = require('format-hours');

module.exports = function(format, hours, options) {
  var standard = { };
  var config = { };

  if (format === 'google') {
    format = 'ddd-ddd "HH:mm-HH:mm, HH:mm-HH:mm"';
    config.combine = false;
    config.closed = 'x';
    standard['Sunday hours'] = 'x';
    standard['Monday hours'] = 'x';
    standard['Tuesday hours'] = 'x';
    standard['Wednesday hours'] = 'x';
    standard['Thursday hours'] = 'x';
    standard['Friday hours'] = 'x';
    standard['Saturday hours'] = 'x';
  }

  hours.forEach(function(day) {
    standard[day.dayOfWeek.slice(18) + ' hours'] = day.opens.slice(0, -3) + '-' + day.closes.slice(0, -3);
  });

  var formatted = formatHours(standard, format, config);

  return formatted.map(function(open) {
    return options.fn(open);
  }).join('');
};
